import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from "antd";
import BreadCrumb from '../../atoms/BreadCrumb'
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import TenantList from "../../molecules/TenantList";
import { getTenantsByID } from './ducks/actions'

const Tenants = props => {
  const [search, setSearch] = useState();
  const [propertiesFilter, setPropertyFilters] = useState({
    page: 1,
    limit: 6,
  })
  const dispatch = useDispatch()
  const { id } = useParams()
  const tenantList = useSelector((state) => state.tenants.tenantList)
  const propertyID = useSelector((state) => state.dashboard.propertyIDB)
  const breadcrumbList: any = [
    {
      label: "Landloard Managment",
      route: "/landlord-management",
    },
    {
      label: "Properties",
      route: `/all-properties/${propertyID}`,
    },
    {
      label: "Tenants",
      route: "/tenants",
    },
  ];
  useEffect(() => {
    dispatch(getTenantsByID(id, propertiesFilter, search))
  }, [id, propertiesFilter])
  const onSearchHandler = (e) => {
    setSearch(e.target.value)
  }
  const generalFilter = (e) => {
    const filters = { ...propertiesFilter, page: e }
    setPropertyFilters(filters)
  }
  useEffect(() => {
    const filterData = () => {
      generalFilter(1)
    }
    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search])

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <SectionHeader
            heading="Tenants"
            total={tenantList.length}
            search={onSearchHandler}
          />
        </Col>
        <Col span={24}>
          <TenantList
            data={tenantList}
            total={tenantList.length}
            pagination={true}
            currentPage={propertiesFilter.page}
            onpageChange={generalFilter}
          />
        </Col>
      </Row>
    </DashboardLayout>

  );
};

export default Tenants;
