import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useDispatch } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { InputField } from "../../../../atoms/FormElement";
import { adminService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";
import { getCategoryRoots, getCategoryChild } from "../../ducks/actions";

const defaultValues: any = {
  name: "",
};

const { Paragraph } = Typography;

const UpdateServices = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues,
  });
  const condition = props.type === "category";
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(props.childDetail);
  }, [props.childDetail]);

  const onFinish = async (value) => {
    setLoader(true);
    setSubmited(true);
    try {
      let url = `${adminService}/config/updateCategory`;
      let postData = {
        categoryName: `${value.name}`,
        categoryId: `${props.childDetail.id}`,
        type: props.childDetail.type == "Sub-Types" ? "sub" : "main",
      };
      await axios.put(url, postData, { headers: { application: 4 } });
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success("Maintenance Type updated successfully");
        condition
          ? dispatch(getCategoryRoots())
          : dispatch(getCategoryChild(props.parentID));
        props.setVisible(false);
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
    }
  };

  console.log(props.childDetail, "childDetail");
  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label={
                  condition
                    ? "Change Category Title"
                    : "Change Sub-Category Title"
                }
                control={control}
                rules={{
                  required: condition
                    ? "Please enter category title"
                    : "Please enter Sub-Category title",
                }}
                iProps={{
                  placeholder: condition ? "Plumbing" : "Water Leakage",
                }}
                setValue={setValue}
                valueGot={props.childDetail.title}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error"
                htmlType="button"
                onClick={() => props.setVisible(false)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default UpdateServices;
