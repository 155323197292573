import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getCategoryChild } from "../../ducks/actions";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import axios from "../../../../../utils/axiosInceptor";
import { adminService } from "../../../../../configs/constants";

const defaultValues: any = {
  name: "",
  assets: "",
};

const { Paragraph } = Typography;

const AddSubType = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, errors, handleSubmit, reset } = useForm({ defaultValues });

  const dispatch = useDispatch();

  const onFinish = async (param) => {
    setLoader(true);
    setSubmited(true);
    try {
      let url = `${adminService}/config/createSubCategory`;
      let postData = {
        parentCategoryId: String(param.assets.value),
        subCategoryName: String(param.name),
      };

      await axios.post(url, postData, { headers: { application: 4 } });
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success("Maintenance Type added successfully");
        dispatch(getCategoryChild(param.assets.value));
        props.setVisible(false);
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
    }
  };

  useEffect(() => {
    reset({ defaultValues });
    props?.setOnClose(false);
  }, [props?.onClose]);

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="assets"
                label="Select Category"
                control={control}
                iProps={{ placeholder: "Select Category" }}
                rules={{ required: "Please select category" }}
                initValue=""
                selectOption={props.category.map((item) => ({
                  value: item.id,
                  label: item.title,
                }))}
                validate={errors.assets && "error"}
                validMessage={errors.assets && errors.assets.message}
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Add Sub-Category Title"
                control={control}
                rules={{
                  required: "Please enter category title",
                }}
                iProps={{ placeholder: "Plumbing" }}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error"
                htmlType="button"
                onClick={() => {
                  reset({ defaultValues });
                  props.setVisible(false);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddSubType;
