import axios from "../../../../utils/axiosInceptor";
import { adminService } from "../../../../configs/constants";
import * as action_types from "./constants";

const category_root = `${adminService}/config/getCategoryRoots`;
const child_category = `${adminService}/config/getCategoryChildren`;


const notiList = `${adminService}/notification`;
const getTimeslotType = `${adminService}/config/time-slot-types`;
const getTimeslotList = `${adminService}/config/time-slots-list`;


export const getConfigList = (type = 'email') => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(notiList + `?type=${type}`);
      console.log('data', result)
      dispatch({
        type: action_types.GET_NOTIFICATION,
        data: result,
      });
     
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getSpecificDetail = (id, type) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${notiList}/${id}?type=${type}`);
   
      dispatch({
        type: action_types.GET_SPECIFIC_DETAIL,
        data: result,
      });
     
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getCategoryRoots = () => {
  return async (dispatch) => {

    try {
      const {
        data: { result },
      } = await axios.get(category_root);
      dispatch({
        type: action_types.GET_CATEGORY_ROOT,
        data: result,
      });
     
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getCategoryChild = (id) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${child_category}/${id}`);
      console.log(result)
      dispatch({
        type: action_types.GET_CATEGORY_CHILD,
        data: result,
      });
     
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTimeSlotTypes = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(getTimeslotType);
      dispatch({
        type: action_types.GET_TIMESLOTS_TYPE,
        data: result,
      });
     
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTimeSlotList = () => {
  return async (dispatch) => {

    try {
      const {
        data: { result },
      } = await axios.get(getTimeslotList);
      dispatch({
        type: action_types.GET_TIMESLOTS_LIST,
        data: result,
      });
     
    } catch (e) {
      console.log("Err", e);
    }
  };
};