import React, { useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import { Row, Col } from "antd";
import BreadCrumb from "../../atoms/BreadCrumb";
import PropertyCards from "./components/AllProperties/PropertyCards";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { getPropertyID } from './ducks/actions'
const breadcrumbList: any = [
  {
    label: "Landloard Managment",
    route: "/landlord-management",
  },
  {
    label: "Properties",
    route: "/properties",
  },
];

const AllProperties = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  useEffect(() => {
    dispatch(getPropertyID(id))
  }, [])
  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
        <Col span={24}>
          <PropertyCards />
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default AllProperties;
