/* eslint-disable react/prop-types */
import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { checkCookie, deleteCookie } from "../utils/helper";
import Login from "../app/pages/Login";
import SignUp from "../app/modules/SignUp";
import ForgotPassword from "../app/modules/Login/ForgotPassword";
import ChangePassword from "../app/modules/Login/ChangePassword";
import Success from "../app/modules/Login/Success";
import Landlord from "../app/modules/Landlord";
import FacilityManager from "../app/modules/FacilityManager";
import Supervisors from "../app/modules/Supervisors";
import TenantListing from "../app/modules/Tenants";
import AllProperties from "../app/modules/AllProperties";
import PropertyDetail from "../app/modules/AllProperties/PropertyDetail";
import NotificationsAlerts from "../app/modules/NotificationsAlerts";
import NotificationDetail from "../app/modules/NotificationsAlerts/NotificationDetail";
import RoleAndAccess from "../app/modules/RoleAndAccess";
import ViewDetails from "../app/modules/Configurations/components/ViewDetails";
import Configurations from "../app/modules/Configurations";
import UserList from "../app/modules/RoleAndAccess/UserList";
import RolePermissions from "../app/modules/RoleAndAccess/RolePermissions";
import AddNotification from "../app/modules/Configurations/components/AddNotification/index";
// import TicketTypes from "../app/modules/Maintenance/TicketTypes/TicketTypes";
import CMSDetail from "../app/modules/Cms/components/CMSDetail";
import Organizations from "../app/modules/Organizations";
import Announcement from "../app/modules/Announcement";
import Tickets from "../app/modules/Organizations/Tickets";
import Plans from "../app/modules/Plans/index";
import Cms from "../app/modules/Cms";
import AddCms from "../app/modules/Cms/components/addCMS";
import PropertyTickets from "../app/modules/AllProperties/PropertyTickets";
import SignUpNew from "../app/modules/SignUpNew";
import OTP from "../app/modules/SignUpNew/OTP";
import CreatePassword from "../app/modules/SignUpNew/CreatePassword";
import PostDatedCheques from "../app/modules/PostDatedCheques";
import Technicians from "../app/modules/Technicians";

const Pages = (props) => {
  const {
    match: { path },
  } = props;

  function isAuthenticated() {
    const login = JSON.parse(localStorage.getItem("login-response"));
    try {
      const { exp } = login;
      if (Date.now() >= exp * 1000) {
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  }

  function clearAll() {
    deleteCookie("landlordUser");
    localStorage.clear();
    sessionStorage.clear();
  }

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={SignUp} />
      <Route exact path="/signup" component={SignUpNew} />
      <Route exact path="/otp" component={OTP} />
      <Route exact path="/create-password" component={CreatePassword} />
      <Route exact path="/success" component={Success} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/change-password" component={ChangePassword} />

      <Route exact path="/dashboard" component={Supervisors} />
      <Route exact path="/supervisors" component={Supervisors} />
      <Route exact path="/configurations" component={Configurations} />
      <Route exact path="/add-new" component={AddNotification} />
      <Route exact path="/view-details/:id" component={ViewDetails} />
      <Route exact path="/all-properties/:id" component={AllProperties} />
      <Route exact path="/landlord-management" component={Landlord} />
      <Route exact path="/property-detail/:id" component={PropertyDetail} />
      <Route exact path="/maintenance/:id" component={PropertyTickets} />
      <Route exact path="/user-list/:id" component={UserList} />
      <Route exact path="/permissions" component={RolePermissions} />
      <Route exact path="/facility-managers" component={FacilityManager} />
      <Route exact path="/technicians" component={Technicians} />
      <Route exact path="/tenants/:id" component={TenantListing} />
      <Route exact path="/organizations" component={Organizations} />
      <Route exact path="/organization-tickets/:id" component={Tickets} />
      <Route exact path="/cms" component={Cms} />
      <Route exact path="/add-cms" component={AddCms} />
      <Route exact path="/cms/:id" component={CMSDetail} />
      <Route exact path="/roles-access-management" component={RoleAndAccess} />
      <Route exact path="/post-dated-cheques" component={PostDatedCheques} />
      <Route exact path="/announcement" component={Announcement} />

      <Redirect to="/login" />
    </Switch>
  );
};

export default Pages;
