import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  Button,
  Typography,
  Input,
  Card,
  Menu,
  Space,
  Dropdown,
} from "antd";

import { Row } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import Grid from "../../../atoms/Grid";
import { getConfigList } from "../ducks/actions";
import Icon from "@ant-design/icons";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Title } = Typography;

const ArrowRightSvg = () => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.17424 6.96857H14.6203C14.6227 6.96857 14.625 6.96388 14.6227 6.96388L9.55081 2.08888C9.14065 1.69513 9.12659 1.04122 9.52268 0.631066C9.91643 0.22091 10.5703 0.206848 10.9805 0.602941L17.8875 7.24044C18.0891 7.43497 18.2039 7.7045 18.2039 7.98341C18.2039 8.26466 18.0891 8.53185 17.8875 8.72638L10.943 15.399C10.7438 15.5912 10.486 15.6873 10.2281 15.6873C9.95627 15.6873 9.68674 15.5818 9.48518 15.3709C9.09143 14.9608 9.10315 14.3068 9.5133 13.9131L14.5852 9.0381C14.5875 9.03575 14.5852 9.03341 14.5828 9.03341H1.15081C0.602368 9.03341 0.154711 8.6045 0.121899 8.06544C0.0867426 7.46544 0.576586 6.96857 1.17424 6.96857Z"
      fill="#184461"
    />
  </svg>
);

const ArrowRight = (props) => <Icon component={ArrowRightSvg} {...props} />;

const Notification = () => {
  const [searchText, setSearchText] = useState();
  const [type, setType] = useState("email");
  const dispatch = useDispatch();
  const history = useHistory();
  const configList = useSelector((state) => state.configuration.notifList);

  useEffect(() => {
    dispatch(getConfigList());
  }, []);

  const onSearch = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText(undefined);
    }
  };
  const SearchFelds = () => {
    return (
      <Input
        type="text"
        onChange={onSearch}
        value={searchText}
        placeholder=""
        suffix={<SearchOutlined className="light-gray" />}
      />
    );
  };
  function handleChange(value) {
    setType(value);
    dispatch(getConfigList(value));
  }

  function viewRender(params) {
    const { templateId, type } = params.data;
    return (
      <Button
        type="link"
        onClick={() =>
          history.push(
            `/view-details/${templateId}?type=${type.replace(" ", "_")}`,
          )
        }
      >
        <ArrowRight style={{ fontSize: 25 }} />
      </Button>
    );
  }

  const columnDefs = [
    {
      headerName: "Id",
      valueGetter: "node.rowIndex + 1",
      minWidth: 40,
      maxWidth: 80,
    },
    { headerName: "Key", field: "key" },
    { headerName: "Subject", field: "subject" },
    { headerName: "Type", field: "type" },
    {
      headerName: "View Details",
      field: "view_details",
      cellRendererFramework: viewRender,
    },
  ];

  const getData = () => {
    type &&
      configList.forEach((index) => (index["type"] = type.replace("_", " ")));
    return configList;
  };

  return (
    <>
      <Row>
        <Title level={5} className="blue-3">
          Select Notification Type
        </Title>
      </Row>
      <Row className="mb-1">
        <Select
          defaultValue="email"
          style={{ width: 200 }}
          onChange={handleChange}
        >
          <Option value="email">Email</Option>
          <Option value="push_notification">Push Notification</Option>
          <Option value="sms">SMS</Option>
        </Select>
      </Row>
      <Card extra={SearchFelds()} className=" ag-nospace-body">
        <Grid
          data={getData()}
          columns={columnDefs}
          filterGrid={searchText}
          pagination={false}
        />
      </Card>
    </>
  );
};

export default Notification;
