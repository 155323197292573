import React, { useState } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useForm } from "react-hook-form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { adminService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";
import { getRoles } from "../../ducks/actions";

var _ = require("lodash");
const { Paragraph } = Typography;

const defaultValues: any = {
  roleName: "",
  description: "",
  applicationId: "",
};

const UpdateRole = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, errors, reset, handleSubmit, setValue } = useForm({ defaultValues });

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    const { roleName, description } = values;
    const postJson = {
      roleName,
      //description,
      roleId: props.roleRow.id
    };
    console.log("dkk", postJson);
    const url = `${adminService}/access/updateRole`;
    try {


      await axios.put(url, postJson, { headers: { application: 4 } });
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        getRoles(props.page, '')
        reset();
        message.success("Role update successfully");
        props.onSubmit();
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
      console.log("check", response);
    }
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="roleName"
                label="Role Name"
                control={control}
                setValue={setValue}
                valueGot={props.roleRow.name}
                rules={{
                  required: "Please enter role name",
                  pattern: {
                    value: /(?=^.{1,50}$)^\S*$/,
                    message: "Input role name without space!",
                  },
                }}
                iProps={{ placeholder: "Enter role name" }}
                initValue=""
                validate={errors.roleName && "error"}
                validMessage={errors.roleName && errors.roleName.message}
              />
            </Col>

            <Col span={12}>
              <InputField
                fieldname="description"
                label="Description"
                control={control}
                iProps={{ placeholder: "Enter description" }}
                initValue=""
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-gray-button-outline"
                htmlType="button"
                onClick={props.onCancel}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Done
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default UpdateRole;
