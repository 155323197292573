// import React, { useState, useEffect } from "react";
// import { Layout, Row, Col, Breadcrumb, Button, Image, Card } from "antd";
// import { Link, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { getSingleProperty } from "../AddProperty/ducks/actions/addInfoAction";
// import { RightOutlined } from "@ant-design/icons";
// import Sidebar from "../../../molecules/Sidebar";
// import Header from "../../../molecules/Header";
// import Footer from "../../../molecules/Footer";
// import SideDetail from "./SideDetail";
// import agEdit from "../../../../assets/img/ag-edit.svg";
// import { Line } from "@ant-design/charts";
// import PropertyInfo from "./propertyInfoTab/index";

// const { Content } = Layout;

// const tabList = [
//   {
//     key: "info",
//     tab: "Property Information",
//   },
//   {
//     key: "units",
//     tab: "Property Units",
//   },
//   {
//     key: "config",
//     tab: "Configurations",
//   },
// ];

// const chartData = [
//   {
//     type: "Vacant",
//     value: 60,
//   },
//   {
//     type: "Occupied",
//     value: 90,
//   },
// ];

// var lineData = [
//   {
//     year: "1994",
//     value: 22,
//   },
//   {
//     year: "1998",
//     value: 20,
//   },
//   {
//     year: "2002",
//     value: 26,
//   },
//   {
//     year: "2006",
//     value: 32,
//   },
//   {
//     year: "2010",
//     value: 34,
//   },
//   {
//     year: "2014",
//     value: 48,
//   },
// ];

// var chartconfig = {
//   appendPadding: 10,
//   data: chartData,
//   angleField: "value",
//   colorField: "type",
//   color: ["#A2D69E", "#184461"],
//   radius: 0.9,
//   innerRadius: 0.79,

//   label: {
//     type: "inner",
//     offset: "-50%",
//     style: { textAlign: "center", opacity: 0 },
//     autoRotate: false,
//     content: "{value}",
//   },

//   legend: {
//     itemName: {
//       spacing: 50,
//       style: {
//         fontSize: 16,
//       },
//     },
//     position: "bottom",
//     layout: "vertical",
//   },
//   state: {
//     active: {
//       style: {
//         lineWidth: 0,
//         fillOpacity: 0.65,
//       },
//     },
//   },
//   statistic: {
//     title: {
//       offsetY: -20,
//       customHtml: () => "Total Units",
//     },
//     content: {
//       style: {
//         whiteSpace: "pre-wrap",
//         overflow: "hidden",
//         textOverflow: "ellipsis",
//       },
//     },
//   },
//   interactions: [
//     { type: "element-selected" },
//     { type: "pie-statistic-active" },
//   ],
// };

// var lineconfig = {
//   height: 300,
//   data: lineData,
//   xField: "year",
//   yField: "value",
//   // seriesField: 'category',
//   yAxis: {
//     label: {
//       formatter: function formatter(v) {
//         return "".concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
//           return "".concat(s, ",");
//         });
//       },
//     },
//   },
//   color: "#184461",
//   point: {
//     shape: "circle",
//     color: "#184461",
//   },
// };

// export default function PropertyDetail() {
//   const params = useParams();
//   const dispatch = useDispatch();
//   const [tabKey, setTabkey] = useState("info");

//   const propertyDetails = useSelector(
//     (state) => state.addInfoReducer.getSingleProperty,
//   );

//   useEffect(() => {
//     dispatch(getSingleProperty(params?.id));
//   }, []);

//   const reviewContent = {
//     info: <PropertyInfo propertyDetails={propertyDetails} />,
//     units: "",
//     config: "",
//   };

//   useEffect(() => {
//     console.log("check");
//   }, []);

//   return (
//     <Layout className="main-layout">
//       <Header />
//       <Layout>
//         <Sidebar />
//         <Layout className="layout-space">
//           <Content className="main-content">
//             <>
//               <Row gutter={[24, 24]}>
//                 <Col span={24}>
//                   <Breadcrumb separator={<RightOutlined />}>
//                     <Breadcrumb.Item>
//                       <Link to="/dashboard">Dashboard</Link>
//                     </Breadcrumb.Item>
//                     <Breadcrumb.Item>
//                       <Link to="/all-properties">Properties</Link>
//                     </Breadcrumb.Item>
//                     <Breadcrumb.Item>{propertyDetails?.name}</Breadcrumb.Item>
//                   </Breadcrumb>
//                 </Col>
//               </Row>

//               <Row gutter={24}>
//                 <Col flex="0 1 354px">
//                   <SideDetail propertyDetails={propertyDetails} />
//                 </Col>
//                 <Col flex="1 1 500px">
//                   <Row gutter={[24, 24]}>
//                     <Col span={18}>
//                       <Card
//                         bordered={false}
//                         bodyStyle={{ padding: "24px 24px" }}
//                         extra={
//                           <Button type="link" className="p-0">
//                             <Image preview={false} src={agEdit} alt="" />
//                           </Button>
//                         }
//                         title="Earnings"
//                       >
//                         <Line {...lineconfig} />
//                       </Card>
//                     </Col>
//                     {/* <Col span={10}>
//                       <Card
//                         bordered={false}
//                         extra={
//                           <Button type="link" className="p-0">
//                             <Image preview={false} src={agEdit} alt="" />
//                           </Button>
//                         }
//                         title="Units"
//                       >
//                         <Pie {...chartconfig} />
//                       </Card>
//                     </Col> */}
//                     <Col span={24}>
//                       <Card
//                         bordered={false}
//                         className="ag-nospace-head ag-nospace-body h-auto tabinner16"
//                         tabList={tabList}
//                         activeTabKey={tabKey}
//                         onTabChange={setTabkey}
//                         tabProps={{ tabBarGutter: 16 }}
//                       >
//                         {reviewContent[tabKey]}
//                       </Card>
//                     </Col>
//                   </Row>
//                 </Col>
//               </Row>

//               <Footer />
//             </>
//           </Content>
//         </Layout>
//       </Layout>
//     </Layout>
//   );
// }
import React from "react";
export default function PropertyDetail() {
  return <h1>PropertyDetail</h1>;
}
