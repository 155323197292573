import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Space,
  Tag,
  Button,
  Image,
  Divider,
  message,
  Rate,
} from "antd";
import { EyeFilled, CloseOutlined, CaretRightFilled } from "@ant-design/icons";
import { maintenanceService } from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";
import moment from "moment";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getTicketReviews } from "../../../ducks/actions";

const { Title, Text } = Typography;

const ViewTicket = (props) => {
  const { data, onCancel, assignTech } = props;
  const dispatch = useDispatch();
  const [review, setReview] = useState({ rating: 5, review: "" });
  const ticketReviews = useSelector(
    (state) => state.organization.getTicketReviews,
  );

  let status = data.CaseStatus?.[0]?.CaseStatusType?.ticket_status;
  let type = data.CaseCategoryClassification?.[0]?.CaseCategory?.category_type;
  let stype =
    data.CaseCategorySecondaryClassification?.[0]?.CaseSubCategory
      ?.category_sub_type;
  let appartment = data.Asset?.property_name;
  let tenant =
    data.CaseRoleTenant?.length > 0 ? data.CaseRoleTenant[0]?.Party?.name : "";
  let technician =
    data.CaseRoleTechnician?.length > 0
      ? data.CaseRoleTechnician[0]?.Party?.name
      : "";
  let recuring = data.ChildCase?.[0]?.parent_id;
  let tickid = data.CaseStatus?.[0]?.case_id;

  useEffect(() => {
    let case_id = props?.data?.CaseCategoryClassification?.[0]?.case_id;
    dispatch(getTicketReviews(case_id));
  }, [props]);

  useEffect(() => {
    if (ticketReviews) {
      setReview(ticketReviews?.[0]);
    }
  }, [ticketReviews]);

  const originalTicket = async (id) => {
    const url = `${maintenanceService}/facilityManager/getTicket/${id}`;
    try {
      const {
        data: { result },
      } = await axios.get(url);
      props.onRecurring(result);
    } catch (e) {
      console.log("Err", e);
    }
  };

  const viewStatus = () => {
    switch (status) {
      case "initial_assessment":
        return `New - ${technician}`;
      case "ag_not_responsible":
        return "AG Not Responsible";
      case "issue_resolved":
        return "Closed";
      case "issue_not_resolved":
        return "Closed";
      case "reject":
        return "Rejected";
      default:
        return `In Progress - ${technician}`;
    }
  };

  // TODO : MAKE CONSTANT
  const tenantStatus = () => {
    switch (status) {
      case "issue_not_resolved":
        return "Not Solved";
      case "ag_not_responsible":
        return "Not Solved";
      case "issue_resolved":
        return "Solved";
    }
  };

  const reopenTicket = async (id) => {
    // TODO : REMOVE PARTY ID
    const url1 = `${maintenanceService}/maintenance/ticket/${id}/reopen-closed?status=inProgress`;
    try {
      const {
        data: { result },
      } = await axios.get(url1);
      message.success("Ticket Reopen Successfully");
      props.onSubmit();
    } catch (e) {
      console.log("Err", e);
    }
  };

  const rejectTicket = (data) => {
    props.onRejectTicket(data);
  };

  return (
    <Row gutter={24}>
      <Col span={24}>
        {!props.onBack && (
          <Button
            className="p-0 h-auto w-auto ag-default mb-1"
            type="link"
            size="large"
            onClick={onCancel}
            icon={<CloseOutlined />}
          />
        )}
      </Col>
      <Col span={15}>
        <Row gutter={[32, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100" direction="vertical">
              <Title level={3}>
                {type} - {stype}
              </Title>
              <Space>
                <Tag className="tag-lg">{type}</Tag>
                <Tag className="tag-lg">{stype}</Tag>
              </Space>
            </Space>
          </Col>
          <Col span={24}>
            <Title level={5}>Description</Title>
            <Title level={5} className="grayBox">
              {data.description}
            </Title>
          </Col>
          <Col span={24}>
            <Title level={5}>Media</Title>
            <Space size={[8, 0]} wrap>
              {data?.totalImageURL &&
                data?.totalImageURL.map((e, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Button className="ticket_thumb" type="link" size="large">
                        <Image
                          preview={true}
                          alt="Ticket"
                          src={e}
                          width="115"
                          height="118"
                        />
                        <EyeFilled className="thumb_icon" />
                      </Button>
                    </React.Fragment>
                  );
                })}
              {data?.totalVideoUrls &&
                data?.totalVideoUrls.map((e, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Button
                        className="ticket_thumb video_thumb"
                        type="link"
                        size="large"
                      >
                        <video
                          src={e}
                          controls
                          width="115"
                          height="118"
                        ></video>
                        <CaretRightFilled className="thumb_icon" />
                      </Button>
                    </React.Fragment>
                  );
                })}
            </Space>
          </Col>

          {data?.ticketStatus == "closed" && (
            <Col span={24}>
              <Title level={5}>Rating and comments:</Title>
              {review?.rating && (
                <Rate
                  disabled
                  defaultValue={review?.rating}
                  count={review?.rating}
                  character={(<StarFilled />) as any}
                  style={{ marginRight: "8px" }}
                />
              )}
              <Rate
                disabled
                count={review?.rating ? 5 - review?.rating : 5}
                character={
                  (<StarOutlined style={{ color: "#fadb1" }} />) as any
                }
              />
              <Title level={5} className="ticket-review">
                {review?.review}
              </Title>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={9}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row align="bottom">
              <Col flex="auto">
                <Title level={5}>Status:</Title>
              </Col>
            </Row>
            <Text className="defaultFont fontWeight600 ag-success">
              {viewStatus()}
            </Text>
          </Col>
          <Col span={24}>
            <Row align="bottom">
              <Col flex="auto">
                <Title level={5}>Tenant:</Title>
              </Col>
            </Row>
            <Text className="defaultFont fontWeight600">{tenant}</Text>
          </Col>
          <Col span={24}>
            <Title level={5}>Apartment:</Title>
            <Text className="defaultFont fontWeight600">{appartment}</Text>
          </Col>
          {status != "issue_resolved" &&
          status != "ag_not_responsible" &&
          status != "issue_not_resolved" ? (
            <></>
          ) : (
            <>
              <Col span={24}>
                <Title level={5}>Technician Assigned:</Title>
                <Text className="defaultFont fontWeight600">
                  {technician && technician}
                </Text>
              </Col>
              <Col span={24}>
                <Title level={5}>Confirmation from Tenant:</Title>
                <Text className="defaultFont fontWeight600 ag-primary">
                  {tenantStatus()}
                </Text>
              </Col>
            </>
          )}

          {recuring && (
            <Col span={24}>
              <Title level={5}>Issue Type:</Title>
              <Text className="defaultFont fontWeight600 ag-primary">
                Recurring -{" "}
                <Button
                  type="link"
                  className="ag-success p-0"
                  onClick={() => originalTicket(recuring)}
                >
                  View original ticket
                </Button>
              </Text>
            </Col>
          )}

          <Divider
            className="m-0"
            style={{ borderBottom: "1px solid #767676" }}
          />
          <Col span={24}>
            <Title level={5}>Date and Time:</Title>
            <Text className="defaultFont fontWeight600">
              {data.ticket_date} |{" "}
              {data.ticket_start_time &&
                moment(data.ticket_start_time, "hh:mm:ss").format("LT")}{" "}
              {data.ticket_end_time &&
                `to ${moment(data.ticket_end_time, "hh:mm:ss").format("LT")}`}
            </Text>
          </Col>
          {status === "initial_assessment" && !technician && !props.onBack && (
            <Col span={24}>
              <Button type="primary" size="large" onClick={assignTech}>
                Assign Technician
              </Button>
            </Col>
          )}
          {props.onBack && (
            <Col span={24}>
              <Button type="primary" size="large" onClick={props.onBack}>
                Go back to ticket
              </Button>
            </Col>
          )}

          <Col span={24}>
            <Space size={8}>
              {status === "issue_not_resolved" && (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => reopenTicket(tickid)}
                >
                  Reopen Ticket
                </Button>
              )}
              {data?.ticketStatus == "progress" && (
                <Button
                  type="primary"
                  size="large"
                  className="danger-btn  px-2"
                  onClick={() => rejectTicket(data)}
                >
                  Reject Ticket
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewTicket;
