import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Typography,
  message,
  Space,
  Spin,
  TimePicker,
} from "antd";
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  InputField,
  TimeField,
  TimeRangeField,
} from "../../../../atoms/FormElement";
import axios from "../../../../../utils/axiosInceptor";
import { adminService } from "../../../../../configs/constants";
import { SelectField } from "../../../../atoms/FormElement";
import { getTimeSlotList } from "../../ducks/actions";
import moment from "moment";

const defaultValues: any = {
  name: "",
};

const { Paragraph } = Typography;

const AddTimeSlot = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, errors, handleSubmit, reset } = useForm({ defaultValues });
  const [indexes, setIndexes] = useState([1]);
  const [counter, setCounter] = useState(30);
  const dispatch = useDispatch();
  const { timeSlotsType } = props;

  const onFinish = async (value) => {
    const timeSlotsFormat = value.timeSlots.map((item) => {
      item.start = item.start_end_time[0].format("HH:mm:ss");
      item.end = item.start_end_time[1].format("HH:mm:ss");
      delete item["start_end_time"]; // Removes json.foo from the dictionary.
      return item;
    });

    value.timeSlots = timeSlotsFormat.filter((el) => el != null);

    setLoader(true);
    setSubmited(true);
    value.timeSlotTypeId = value.timeSlotTypeId.value;
    try {
      let url = `${adminService}/config/add-time-slot`;
      await axios.post(url, value, { headers: { application: 4 } });
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success("Timeslot added successfully");
        // dispatch(getTimeSlotList());
        props?.setOnRefresh("1");
        props.setVisible(false);
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
    }
  };

  const addMoretIimeSlots = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeMoretIimeSlots = (index) => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
  };

  const resetForm = () => {
    reset({ defaultValues });
    setIndexes([1]);
    setCounter(30);
    props?.setOnClose(false);
  };

  useEffect(() => {
    resetForm();
  }, [props?.onClose]);

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="timeSlotTypeId"
                label="Type"
                control={control}
                iProps={{ placeholder: "Select type", isMulti: false }}
                rules={{ required: "Please select type" }}
                // initValue={defaultBuiladSelected}
                selectOption={timeSlotsType}
                validate={errors.timeSlotTypeId && "error"}
                validMessage={
                  errors.timeSlotTypeId && errors.timeSlotTypeId.message
                }
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="description"
                fieldKey="description"
                label="Description"
                control={control}
                rules={{
                  required: "Please enter description",
                }}
                iProps={{ placeholder: "Description" }}
                initValue=""
                validate={errors.description && "error"}
                validMessage={errors.description && errors.description.message}
              />
            </Col>
          </Row>
          {indexes.map((index) => {
            const fieldName = `timeSlots[${index}]`;

            return (
              <Row gutter={24} key={fieldName}>
                <Col span={20}>
                  <TimeRangeField
                    isRequired={true}
                    fieldname={`${fieldName}.start_end_time`}
                    fieldKey={`${fieldName}.start_end_time`}
                    label="Start Time / End Time"
                    control={control}
                    rules={{
                      required: "Please select start & end time.",
                      validate: (value) =>
                        moment(value[0]).format("HH:mm:ss") !==
                          moment(value[1]).format("HH:mm:ss") ||
                        "Incorrect Time",
                    }}
                    iProps={{ format: "h:mm a" }}
                    initValue=""
                    validate={
                      errors?.["timeSlots"]?.[index]?.start_end_time && "error"
                    }
                    validMessage={
                      errors?.["timeSlots"]?.[index]?.start_end_time &&
                      errors?.["timeSlots"]?.[index]?.start_end_time?.message
                    }
                  />
                </Col>
                {/* <Col span={10}>
                            <TimeField
                              isRequired={true}
                              fieldname={`${fieldName}.end`}
                              fieldKey={`${fieldName}.end`}
                              label="End Time"
                              control={control}
                              rules={{
                                required: "Please enter landlord name",
                              }}
                              iProps={{ placeholder: "Ahmed Moustapha",format:'h:mm a ' }}
                              initValue=""
                              validate={errors.name && "error"}
                              validMessage={errors.name && errors.name.message}
                            />
                          </Col> */}
                <Col span={4}>
                  {index > 1 && (
                    <Form.Item className="remove-more-timeslot" label=".">
                      <Button
                        type="dashed"
                        onClick={() => removeMoretIimeSlots(index)}
                        block
                        icon={<MinusCircleOutlined />}
                      >
                        Remove
                      </Button>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            );
          })}
          {indexes.length < 30 && (
            <Form.Item className="add-more-timeslot">
              <Button
                type="dashed"
                onClick={() => addMoretIimeSlots()}
                block
                icon={<PlusOutlined style={{ fontSize: "14px" }} />}
              >
                Add more time slots
              </Button>
            </Form.Item>
          )}
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error"
                htmlType="button"
                onClick={() => {
                  resetForm();
                  props.setVisible(false);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddTimeSlot;
