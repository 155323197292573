import React, { useState, useEffect } from "react";
import { Button, Row, Col, Typography, Space } from "antd";
import Grid from "../../../atoms/Grid";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Popup, popupConfirm } from "../../../atoms/Popup";
import AddTimeSlot from "./Modals/AddTimeSlot";
import { useSelector, useDispatch } from "react-redux";
import {getTimeSlotList, getTimeSlotTypes} from "../ducks/actions";
import moment from 'moment';
import UpdateTimeSlot from "./Modals/UpdateTimeSlot";
import { adminService } from "../../../../configs/constants";
import axios from "axios";

const { Title } = Typography;

const TimeSlots = ({setTrigger,trigger,setVisible,visible}) => {
    const [searchText, setSearchText] = useState();
    const [timeSlotRowData,setTimeSlotRowData] = useState({})
    const dispatch = useDispatch();
    const timeSlotsList = useSelector((state) => state.configuration.timeslotsList);
    const timeSlotsType= useSelector((state) => state.configuration.timeSlotsType);
    const [onClose, setOnClose] = useState(false);
    const [onRefresh, setOnRefresh] = useState('0');
    const [timeSlotRows,setTimeSlotRows] = useState([])
    const [rowRefresh,setRowRefresh] = useState(false)

      useEffect(() => {
        dispatch(getTimeSlotList());
        dispatch(getTimeSlotTypes());
      }, []);


      useEffect(()=>{
        let data:any= [];
        if(timeSlotsList)
        {
          for (const [key, value] of Object.entries(timeSlotsList)) {
          let rowData:any = value;
          let indexItem:any =[];
          let rowItem:any =[];
            rowData?.map((item,index)=>{
              indexItem.push(index+1)
               data.push((
                 {
                    ...{ list:value },
                   ...item,
                   ...{TimeAvailabilityType:key},
                   ...{indexItem}
                  }
                 ))
            })
          }
        }
        setTimeSlotRows(data)
      },[timeSlotsList])

    const popup = [
        {
          title: (
            <Title level={3} className="ag-primary mb-0">
              Add a Time Slot
            </Title>
          ),
          content: (
            <AddTimeSlot
                onClose={onClose} 
                setOnClose={setOnClose}
                setVisible={setVisible}
                setOnRefresh={setOnRefresh}
                setTimeSlotRows={setTimeSlotRows}
                timeSlotsType={timeSlotsType?.map((index) => ({
                label: index.name,
                value: index.id,
              }))}
            />
          ),
          width: 650,
        },
        {
          title: (
            <Title level={3} className="ag-primary mb-0">
              Update a Time Slot
            </Title>
          ),
          content: (
            <UpdateTimeSlot
             setVisible={setVisible}
             onClose={onClose} 
             setOnClose={setOnClose}
             onRefresh={onRefresh}
             setTimeSlotRows={setTimeSlotRows}
             setOnRefresh={setOnRefresh}
             rowData={timeSlotRowData}
             timeSlotsType={timeSlotsType?.map((index) => ({
             label: index.name,
             value: index.id,
            }))}
            />
          ),
          width: 650,
        },
        {
          title: (
            <Title level={3} className="ag-primary mb-0">
              Delete a Time Slot
            </Title>
          ),
          content: (
            <UpdateTimeSlot
             setVisible={setVisible}
             rowData={timeSlotRowData}
             timeSlotsType={timeSlotsType?.map((index) => ({
              label: index.name,
              value: index.id,
            }))}
            />
          ),
          width: 650,
        },
      ];

      useEffect(()=>{
        setOnRefresh('0')
        setTimeSlotRows([])
        dispatch(getTimeSlotList());
      },[onRefresh])

    const onCancel=()=>{
        setOnClose(true)
        setVisible(false)
    }

    const popupProps = {
        closable: true,
        visibility: visible,
        title: popup[trigger].title,
        content: popup[trigger].content,
        width: popup[trigger].width,
        onCancel: () => onCancel(),
      };


    const handleUpdate = (data) => {
      setVisible(true);
      setTrigger(1);
      setTimeSlotRowData( data?.data   )
    };


    const deleteTimeSlots = (item: any) => {
     
      return popupConfirm({
        title: `Are you sure you want to delete`,
        cancelText: "Back",
        okText: "Delete",
        okType: "danger",
        onOk() {
          const url = `${adminService}/config/delete-time-slot`;
          try {
            axios
              .post(
                url,
                {
                  timeSlotId: item?.data?.id,
                },
                { headers: { application: 4 } },
              )
              .then((res) => {
                dispatch(getTimeSlotList());
              });
          } catch (e) {
            console.log(e);
          }
        },
      });
    };

    const actionEditDelete = (params) => {
        return (
            <>
              <Space size={16}>
                <Button type="link" 
                onClick={() => handleUpdate(params)}
                className="p-0 ag-success">
                  <EditOutlined />
                </Button>
                {/* <Button type="link"   danger className="p-0">
                  <DeleteOutlined style={{ color: '#FB7171' }}    onClick={() => deleteTimeSlots(params)} />
                </Button> */}
              </Space>
            </>
        )
    }

    

    const startDateFormat = (params)=>{
     const [startDate,endDate] =  params.data.timeSlot.split("-")
      return  moment(startDate, ["h:mm:ss"]).format("h:mm A");
    }

    const endDateFormat = (params)=>{
      const [startDate,endDate] =  params.data.timeSlot.split("-")
       return  moment(endDate, ["h:mm:ss"]).format("h:mm A");
     }

    const columnDefs = [
        {
          headerName: "Id",
          field: "id",
          valueGetter: "node.rowIndex + 1",
          minWidth: 40,
          maxWidth: 80,
        },
        { 
          headerName: "Type", 
          field: "TimeAvailabilityType"
         },
        { headerName: "Description", field: "description" },
        { 
          headerName: "Start Time", 
          field: "start",
          cellRendererFramework: startDateFormat,
         },
         { 
          headerName: "End Time", 
          field: "end",
          cellRendererFramework: endDateFormat,
         },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: actionEditDelete,
        },
      ];
    
      const gridProps = {
        sortable: true,
        filter: false,
        resizable: true,
      };

    return (
            <>
                <Row gutter={24}>
                  <Col xl={24}>
                    {timeSlotsList  && (
                        <Grid
                          data={timeSlotRows}
                          columns={columnDefs}
                          pagination={false}
                          defaultSettings={gridProps}
                          filterGrid={searchText}
                          noRowlabel="TimeSlots"
                          // noRowbtnAction={addTechnician}
                        />
                        )}
                    </Col>
                </Row>
                <Popup {...popupProps} />
            </>
        );
};

export default TimeSlots;