import { Button, Col, Row, Space, Table, Typography } from "antd";
import React, { useState, useEffect } from "react";
import BreadCrumb from "../../atoms/BreadCrumb";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Popup } from "../../atoms/Popup";
import AddRole from "./components/AddRole";
import UpdateRole from "./components/UpdateRole";
import AddUser from "./components/AddUser";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRoles, getContentCategories, getApplication } from "./ducks/actions";

const { Title, Text } = Typography;

const breadcrumbList: any = [
  {
    label: "Roles & Access Management",
    route: "/roles-access-management",
  },
];

const RoleAndAccess = (props) => {
  const dispatch = useDispatch();
  const roleList = useSelector((state) => state.roles.rolesData);
  const appIds = useSelector((state) => state.roles.appData);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const history = useHistory();
  const [page, setPage] = useState(1)
  const [updateRow, setUpdateRow] = useState({});
  const [onClose, setOnClose] = useState(false);
  const [search, setSearch] = useState('')
  console.log('appIds', appIds)
  useEffect(() => {
    dispatch(getRoles(1, ''));
    dispatch(getContentCategories());
    dispatch(getApplication());
  }, []);

  const addUser = () => {
    setVisible(true);
    setTrigger(1);
  };
  const addRole = () => {
    setVisible(true);
    setTrigger(0);
  };

  const onAddRole = () => {
    dispatch(getRoles(1, ''));
    setVisible(false);
  };

  const onUpdateRole = () => {
    dispatch(getRoles(1, ''));
    setVisible(false);
  }

  const updateRoleModel = (role) => {
    setTrigger(2)
    setVisible(true);
    setUpdateRow(role)
    console.log(role, 'text?.id')
  }


  const onTopSearch = (e) => {
    setSearch(e.target.value)
  };
  useEffect(() => {
    const filterData = () => {
      setPage(1)
      dispatch(getRoles(1, search))
    }
    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search])

  const columns: any = [
    {
      title: "Role Name",
      dataIndex: "name",
      key: "name",
      render: (text,value) => (
        <Button
          type="link"
          className="p-0"
          onClick={() => history.push(`/user-list/${value.name}`)}
        >
          {text}
        </Button>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "actions",
    //   key: "actions",
    //   width: 150,
    //   render: (text, record) => {
    //     return <Space size={16}>
    //       <Button type="link" onClick={() => updateRoleModel(record)} className="p-0 ag-success">
    //         <EditOutlined />
    //       </Button>
    //       <Button type="link" danger className="p-0">
    //         <DeleteOutlined />
    //       </Button>
    //     </Space>
    //   },
    // },
  ];

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add New Role
        </Title>
      ),
      content: (
        <AddRole
          onClose={onClose}
          setOnClose={setOnClose}
          appIds={appIds}
          page={page}
          onSubmit={onAddRole}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Add New User
        </Title>
      ),
      content: <AddUser editMode={false} onClose={onClose} setOnClose={setOnClose} popUpClose={setVisible} onCancel={() => setVisible(false)} />,
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Edit Role
        </Title>
      ),
      content: (
        <UpdateRole
          roleRow={updateRow}
          appIds={appIds}
          page={page}
          onSubmit={onUpdateRole}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
  ];

  const onCancel = () => {
    setOnClose(true)
    setVisible(false)
  }
  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => onCancel(),
  };

  return (
    <>
      <DashboardLayout>
        {/* <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row> */}

        <SectionHeader
          heading="Roles &amp; Access Management"
          addNewAction={addRole}
          addMoreButton={addUser}
          addText={"Role"}
          addMoreText={"User"}
          search={onTopSearch}
        />
        <Row gutter={24}>
          <Col span={24}>
            <Table
              className="custom-table"
              columns={columns}
              pagination={{
                current: page,
                pageSize: 50,
                showSizeChanger: false,
                className: "ag-property-text-pagination mb-1",
                position: ["topLeft"],
                onChange: (e) => {
                  setPage(e)
                  dispatch(getRoles(e, search))
                },
                total: (roleList?.count),
                showTotal: (total, range) =>
                  `Showing ${range[1]}-${total} Roles`,
              }}
              dataSource={roleList?.rows}
              scroll={{ y: 500 }}
            />
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};

export default RoleAndAccess;
