import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { adminService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";
import {
  getOrganizationList,
  getContentCategories,
  getusersRolesApplication,
} from "../../ducks/actions";

const { Paragraph } = Typography;
var _ = require("lodash");

const defaultValues = {
  name: "",
  email_address: "",
  phone_number: "",
  organizationId: "",
  user: [],
};

const defaultValues1 = {
  name: "",
  email_address: "",
  phone_number: "",
  organizationId: "",
  user: [
    {
      application: "",
      role: "",
    },
  ],
};

const user = {
  application: "",
  role: "",
};

const Item = ({
  item,
  itemIndex,
  register,
  control,
  remove,
  setValue,
  errors,
  roles,
  editMode,
}) => {
  const app: any = useWatch({
    control,
    name: `user[${itemIndex}].application`,
    defaultValue: "",
  });

  const [roleState, setRoleState] = useState([]);

  useEffect(() => {
    if (app) {
      let x: any = {};
      if (editMode == false) {
        setValue(`user[${itemIndex}].role`, null);
      }
      x = roles.find((e) => e.id == app.value);
      setRoleState(x?.ApplicationRole);
    }
  }, [app]);

  return (
    <Row gutter={24} style={{ position: "relative" }}>
      <Col span={12}>
        <SelectField
          isRequired={true}
          fieldname={`user[${itemIndex}].application`}
          label="Application"
          control={control}
          iProps={{ placeholder: "Select Application" }}
          rules={{ required: "Please select application" }}
          initValue={item.application}
          selectOption={_.map(roles, (e) => ({ value: e.id, label: e.name }))}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.user?.length > 0 &&
            errors?.user[itemIndex]?.application &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.user?.length > 0 &&
            errors?.user[itemIndex]?.application &&
            errors?.user[itemIndex]?.application?.message
          }
        />
      </Col>
      <Col span={12}>
        <SelectField
          isRequired={true}
          fieldname={`user[${itemIndex}].role`}
          label="Roles"
          control={control}
          rules={{ required: "Please select role" }}
          iProps={{ placeholder: "Select one", isMulti: true }}
          initValue={item.role}
          selectOption={_.map(roleState, (item) => ({
            value: item.id,
            label: item.name,
          }))}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.user?.length > 0 &&
            errors?.user[itemIndex]?.role &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.user?.length > 0 &&
            errors?.user[itemIndex]?.role &&
            errors?.user[itemIndex]?.role?.message
          }
        />
      </Col>
      {itemIndex != 0 && (
        <>
          <Button
            type="link"
            className="ag-bold p-0 removebtn"
            icon={<MinusCircleOutlined />}
            onClick={() => remove(itemIndex)}
          />
        </>
      )}
    </Row>
  );
};

const AddUser = (props) => {
  const dispatch = useDispatch();
  const organizationList = useSelector((state) => state.roles.organizationData);
  const applicationRoles = useSelector((state) => state.roles.applicationData);
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formdefault, setFormdefault] = useState(defaultValues);
  const userAppRolesSelected = useSelector(
    (state) => state.roles.userApplicationRoles,
  );
  const {
    control,
    errors,
    reset,
    handleSubmit,
    register,
    trigger,
    setValue,
  } = useForm({ defaultValues });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "user",
  });

  const resetForm = () => {
    if (props.editMode == false) {
      reset(defaultValues1);
    }
    props?.setOnClose(false);
  };

  useEffect(() => {
    resetForm();
  }, [props?.onClose]);

  useEffect(() => {
    dispatch(getOrganizationList());
    dispatch(getContentCategories());
  }, []);

  useEffect(() => {
    if (props.data) {
      let phoneNumber;
      let phNumber =
        props?.data?.contact_info?.telecommunication_number &&
        props?.data?.contact_info?.telecommunication_number[0]?.contact_number;
      if (phNumber && phNumber.substring(0, 4) === "+971") {
        phoneNumber = phNumber.substring(4);
      } else {
        phoneNumber = "";
      }
      if (props.data.application_user_id) {
        dispatch(getusersRolesApplication(props.data.application_user_id));
      }

      setValue("name", props?.data?.first_name || ""),
        setValue(
          "email_address",
          props?.data?.contact_info?.electronic_address[0]?.email || "",
        );
      if (phoneNumber) {
        setValue("phone_number", parseFloat(phoneNumber));
      }

      if (organizationList && organizationList?.rows) {
        const selectedOrg = organizationList.rows.find(
          (item) => item.name == props?.data?.organisation,
        );
        setValue(
          "organizationId",
          {
            value: selectedOrg.PartyRole.id,
            label: selectedOrg.name,
          } || "",
        );
      }
    }
  }, [props.data, organizationList]);

  useEffect(() => {
    if (userAppRolesSelected.length > 0 && props.data?.application_user_id) {
      let xuser = [];
      userAppRolesSelected.map((item) => {
        let y: any = {};
        y = applicationRoles.find((x) => x.name == item.applicationName);
        if (y) {
          let roles = [];
          item.ApplicationRoles.map((z) =>
            roles.push({ value: z.id, label: z.role }),
          );
          xuser.push({
            application: { label: y.name, value: y.id },
            role: roles,
          });
        }
      });
      setValue("user", xuser);
    }
  }, [userAppRolesSelected]);

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);

    const organizationId = values.organizationId.value;

    const arrayObjID = values.user.map((e) => {
      return e.role.map((res) => res.value);
    });

    const arrayObjName = values.user.map((e) => {
      return e.role.map((res) => res.label);
    });

    var roleNameArray = arrayObjName.flat();
    var uniqueRoles = roleNameArray.filter((v, i, a) => a.indexOf(v) === i);

    const applicationRoleId = arrayObjID.flat();
    const applicationRoleName = uniqueRoles;
    const { name, email_address, phone_number } = values;

    let postJson = {};
    if (props.editMode == false) {
      postJson = {
        name,
        email_address,
        phone_number,
        organizationId,
        applicationRoleId,
        applicationRoleName,
      };
      const url = `${adminService}/admin/addUser`;
      try {
        await axios.post(url, postJson, { headers: { application: 4 } });
        setTimeout(() => {
          setSubmited(false);
          setLoader(false);
          props.popUpClose(false);
          message.success("User added successfully");
          reset(defaultValues1);
        }, 3000);
      } catch (e) {
        const { response } = e;
        console.log("checasdk", response);
        message.error(response?.data?.message);
        setSubmited(false);
        setLoader(false);
      }
    } else {
      let delroles = [];
      let newroles = [];
      let oldroles = [];

      // let neworganization = {};
      let oldorganization = {};

      if (userAppRolesSelected.length > 0) {
        userAppRolesSelected?.map((item) => {
          item?.ApplicationRoles.forEach((res) => {
            values?.user.map((e) => {
              let x = e.role.find((y) => y.value == res.id);
              if (x) {
                oldroles.push(x.value);
              } else {
                delroles.push(res.id);
              }
            });
          });
        });
      }

      if (values?.user?.length > 0) {
        values?.user.map((e) => {
          userAppRolesSelected?.map((item) => {
            item?.ApplicationRoles.forEach((res) => {
              let x = e.role.find((y) => y.value == res.id);
              console.log("x", x, res.id, e.role);
              if (!x) {
                newroles.push(e.role[0].value);
              }
            });
          });
        });
      }

      if (props?.data?.organisation) {
        const oldOrg = organizationList.rows.find(
          (item) => item.name == props?.data?.organisation,
        );
        oldorganization = oldOrg?.PartyRole?.id;
      }

      postJson = {
        name,
        email_address,
        phone_number,
        organizationId: organizationId,
        applicationRoleId: newroles,
        partyId: props.data?.party_id,
        applicationUserId: props.data?.application_user_id,
        applicationUserRole: delroles,
        oldOrganizationId: oldorganization,
      };
      console.log("post", postJson);
      const url = `${adminService}/admin/updateUser`;
      try {
        await axios.put(url, postJson, { headers: { application: 4 } });
        setTimeout(() => {
          setSubmited(false);
          setLoader(false);
          props.popUpClose(false);
          dispatch(getOrganizationList());
          message.success("User updated successfully");
        }, 3000);
      } catch (e) {
        const { response } = e;
        console.log("checasdk", response);
        message.error(response?.data?.message);
        setSubmited(false);
        setLoader(false);
      }
    }
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        {loader && !isError && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space
                size={24}
                className="w-100 text-center"
                direction="vertical"
              >
                <Spin size="large" className="ag-fontSize32" />
              </Space>
            </Col>
          </Row>
        )}
        {!submited ? (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  fieldname="name"
                  label="Name"
                  control={control}
                  rules={{
                    required: "Please enter full name",
                  }}
                  iProps={{ placeholder: "Enter Fullname" }}
                  initValue=""
                  validate={errors.name && "error"}
                  validMessage={errors.name && errors.name.message}
                />
              </Col>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  fieldname="email_address"
                  label="Email Address"
                  control={control}
                  rules={{
                    pattern: {
                      value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Enter valid Email Address",
                    },
                    required: "Please enter Email Address",
                  }}
                  iProps={{ placeholder: "ahmed.moustapha@gmail.com" }}
                  initValue=""
                  validate={errors.email_address && "error"}
                  validMessage={
                    errors.email_address && errors.email_address.message
                  }
                />
              </Col>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  label="Phone"
                  fieldname="phone_number"
                  control={control}
                  validate={errors.phone_number && "error"}
                  validMessage={
                    errors.phone_number && errors.phone_number.message
                  }
                  initValue=""
                  rules={{
                    minLength: {
                      value: 9,
                      message:
                        "Please enter valid mobile number, 9 digits required",
                    },
                    maxLength: {
                      value: 9,
                      message:
                        "Please enter valid mobile number, 9 digits required",
                    },
                    required: "Please enter Mobile No.",
                    setValueAs: (value) => `+971${value}`,
                  }}
                  iProps={{
                    placeholder: "Add Mobile Number",
                    addonBefore: "+971",
                    type: "number",
                  }}
                />
              </Col>
              <Col span={12}>
                <SelectField
                  isRequired={true}
                  fieldname="organizationId"
                  label="Organization"
                  control={control}
                  validate={errors.organizationId && "error"}
                  validMessage={
                    errors.organizationId && errors.organizationId.message
                  }
                  iProps={{ placeholder: "Select Organization" }}
                  rules={{ required: "Please select organization" }}
                  initValue=""
                  selectOption={
                    organizationList?.rows &&
                    organizationList.rows.map((e) => {
                      return { value: e.PartyRole.id, label: e.name };
                    })
                  }
                />
              </Col>
            </Row>
            {fields.map((item, itemIndex) => (
              <Item
                key={item.id}
                item={item}
                itemIndex={itemIndex}
                remove={remove}
                register={register}
                control={control}
                errors={errors}
                setValue={setValue}
                roles={applicationRoles}
                editMode={props?.editMode}
              />
            ))}
            <Row gutter={24} justify="space-between">
              <Col span={24}>
                <Button
                  type="link"
                  className="ag-bold p-0"
                  onClick={() => append(user)}
                >
                  + Add a New Application
                </Button>
              </Col>
            </Row>

            <Row gutter={24} justify="space-between">
              <Col>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    reset();
                    props.onCancel();
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col>
                <Button className="ag-green-button" htmlType="submit">
                  {props?.editMode == false ? "Add" : "Update"}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          isError && (
            <>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Space
                    size={24}
                    className="w-100 text-center"
                    direction="vertical"
                  >
                    <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                    <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                      {errorMessage}
                    </Paragraph>
                  </Space>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    className="ag-gray-button-outline"
                    htmlType="button"
                    onClick={() => {
                      reset();
                      setSubmited(false);
                      setisError(false);
                    }}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </>
          )
        )}
      </Form>
    </>
  );
};

export default AddUser;
