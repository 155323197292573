import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination, Row, Col, Input, Tabs, Select, message, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getAssignedList, getUnAssignedList, getZones } from "./ducks/actions";
import Grid from "../../atoms/Grid";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import axios from "../../../utils/axiosInceptor";
import { adminService } from "../../../configs/constants";
import { LoadingOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const [searchText, setSearchText] = useState();
  const [tabKey, setTabkey] = useState("1");
  const [page, setPage] = useState(1);
  const [IDs, setIDs] = useState(1);
  const [page2, setPage2] = useState(1);
  const [assignIDs, setAssignIDs] = useState(1);
  const dispatch = useDispatch();
  const [zoneList, setZoneList] = useState([]);
  const [unassign, setUnassigned] = useState([]);
  const [assign, setAssigned] = useState([]);
  const [loader, setLoader] = useState(false);

  const assignedList = useSelector((state) => state.technician.assignedList);
  const assignedCount = useSelector((state) => state.technician.assignedCount);
  const unassignedList = useSelector(
    (state) => state.technician.unassignedList,
  );
  const unassignedCount = useSelector(
    (state) => state.technician.unassignedCount,
  );
  const zones = useSelector((state) => state.technician.zones);

  const getList = () => {
    dispatch(getAssignedList());
    dispatch(getUnAssignedList());
    dispatch(getZones());
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (zones.length > 0) {
      setZoneList(zones);
    }
  }, [zones]);

  useEffect(() => {
    if (Object.keys(unassignedList).length > 0) {
      let temp = [];
      unassignedList.rows.map((x) => {
        temp.push({
          id: x.id,
          name: x.name,
          email:
            x.PartyContactMechanismEmail[0]?.ContactMechanism?.ElectronicAddress
              ?.email ||
            x.PartyContactMechanismEmail[1]?.ContactMechanism?.ElectronicAddress
              ?.email,
          mobile:
            x.PartyContactMechanismTelephone[0]?.ContactMechanism
              ?.TelecommunicationNumber?.contact_number ||
            x.PartyContactMechanismTelephone[1]?.ContactMechanism
              ?.TelecommunicationNumber?.contact_number,
        });
      });
      setUnassigned(temp);
    }
  }, [unassignedList]);

  useEffect(() => {
    if (Object.keys(assignedList).length > 0) {
      let temp = [];
      assignedList.rows.map((x) => {
        temp.push({
          id: x.id,
          name: x.name,
          email:
            x.PartyContactMechanismEmail[0]?.ContactMechanism?.ElectronicAddress
              ?.email ||
            x.PartyContactMechanismEmail[1]?.ContactMechanism?.ElectronicAddress
              ?.email,
          mobile:
            x.PartyContactMechanismTelephone[0]?.ContactMechanism
              ?.TelecommunicationNumber?.contact_number ||
            x.PartyContactMechanismTelephone[1]?.ContactMechanism
              ?.TelecommunicationNumber?.contact_number,
          zone: x.ZoneRole?.zone_id,
        });
      });
      setAssigned(temp);
    }
  }, [assignedList]);

  const onChangeZone = (e, item) => {
    setLoader(true);
    let body = {
      party_id: item.data.id,
    };
    if (e) {
      body["zone_id"] = e;
    }
    if (item?.data?.zone) {
      body["delete_zone_id"] = item.data.zone;
    }
    axios
      .post(`${adminService}/admin/assignZoneToTechnicians `, body)
      .then((res) => {
        setLoader(false);
        getList();
        message.success("Zone successfully Assigned");
      })
      .catch((err) => {
        setLoader(false);
        const { response } = err;
        message.error(response.data.message);
      });
  };

  const zoneComponent = (params) => {
    return (
      <Select
        className="Sentence active-select ag-blue"
        placeholder="Select"
        style={{ width: 170 }}
        allowClear
        onChange={(e) => onChangeZone(e, params)}
        defaultValue={params?.data?.zone ? params?.data?.zone : null}
      >
        {console.log("---", params?.data)}
        {zoneList.map((x) => (
          <Select.Option
            key={x.id}
            value={x?.id}
            className="Sentence active-select"
          >
            {x?.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const unassignCol = [
    {
      headerName: "#",
      valueGetter: `${IDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: "left",
    },
    {
      headerName: "ID",
      field: "id",
      hide: true,
      maxWidth: 140,
      pinned: "left",
    },
    { headerName: "Name", field: "name" },
    { headerName: "Email Address", field: "email" },
    {
      headerName: "Mobile Number",
      field: "mobile",
    },
    {
      headerName: "Zone",
      field: "zone",
      cellRendererFramework: zoneComponent,
    },
  ];
  const assignedCol = [
    {
      headerName: "#",
      valueGetter: `${assignIDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: "left",
    },
    {
      headerName: "ID",
      field: "id",
      maxWidth: 140,
      hide: true,
      pinned: "left",
    },
    { headerName: "Name", field: "name" },
    { headerName: "Email Address", field: "email" },
    { headerName: "Mobile Number", field: "mobile" },
    {
      headerName: "Zone",
      field: "zone",
      cellRendererFramework: zoneComponent,
    },
  ];

  const fetchData = (e) => {
    if (e >= 2) {
      if (e > page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      } else if (e < page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      }
    } else {
      setIDs(e);
      setPage(e);
    }

    dispatch(getUnAssignedList(e));
  };

  const fetchPagData = (e) => {
    if (e >= 2) {
      if (e > page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      } else if (e < page2) {
        setAssignIDs((e - 1) * 50 + 1);
        setPage2(e);
      }
    } else {
      setAssignIDs(e);
      setPage2(e);
    }
    dispatch(getAssignedList(e));
  };

  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader
          heading="Technicians"
          total={tabKey === "2" ? assignedCount : unassignedCount}
          //addNewAction={addFacility}
        />
        <Spin indicator={antIcon} size="large" spinning={loader}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Tabs
                defaultActiveKey={tabKey}
                onChange={(key) => setTabkey(key)}
                tabBarExtraContent={searchField}
                tabBarGutter={48}
                className="ag-property-status"
              >
                <TabPane tab="Unassigned" key="1">
                  {zoneList.length > 0 && (
                    <>
                      <Grid
                        data={unassign}
                        columns={unassignCol}
                        filterGrid={searchText}
                        pagination={false}
                      />
                      <Row justify="end" className="mt-1">
                        <Pagination
                          className="ag-property-text-pagination"
                          current={page}
                          total={unassignedCount && unassignedCount}
                          onChange={fetchData}
                          showSizeChanger={false}
                          pageSize={50}
                        />
                      </Row>
                    </>
                  )}
                </TabPane>
                <TabPane tab="Assigned" key="2">
                  <Grid
                    data={assign}
                    columns={assignedCol}
                    filterGrid={searchText}
                    pagination={false}
                  />
                  <Row justify="end" className="mt-1">
                    <Pagination
                      className="ag-property-text-pagination"
                      current={page2}
                      total={assignedCount && assignedCount}
                      onChange={fetchPagData}
                      showSizeChanger={false}
                      pageSize={50}
                    />
                  </Row>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Spin>
      </DashboardLayout>
    </>
  );
};
