import { Button, Col, Row, Space, Table, Typography, message } from "antd";
import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import { EditOutlined } from "@ant-design/icons";
import { Popup } from "../../atoms/Popup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddOrganization from "./AddOrganization";
import { getOrganizations } from './ducks/actions'
const { Title, Text } = Typography;

export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState();
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [updates, setUpates] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1);
  const [data, setData] = useState()
  const organization = useSelector((state: any) => state.organization.getOrganizations)

  // useEffect(() => {
  //   dispatch(getOrganizations(1, ''))
  // }, []);

  // useEffect(() => {
  //   setData(organization);
  // }, [organization])

  const addNew = () => {
    setVisible(true);
    setTrigger(0);
  };

  const onEdit = (record) => {
    setVisible(true);
    setTrigger(1);
    setSelected(record);
  };

  const onAdd = () => {
    setVisible(false);
    dispatch(getOrganizations(1, search))
  };

  const onTopSearch = (e) => {
    setSearch(e.target.value)
  };
  useEffect(() => {
    const filterData = () => {
      setPage(1)
      dispatch(getOrganizations(1, search))
    }
    const debouncer = setTimeout(() => {
      filterData();
    }, 1500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search])


  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 220,
    },
    {
      title: "Maintenance Tickets",
      dataIndex: "tickets",
      key: "tickets",
      render: (text, record) => {
        return (
          <Button
            type="link"
            className="p-0 ag-primary"
            onClick={() => history.push({ pathname: `/organization-tickets/${record.PartyRole.id}`, state: { orgName: record.name } })}
          >
            View Tickets
          </Button>)
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 150,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => onEdit(record)}
              className="p-0 ag-success"
            >
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add New Organization
        </Title>
      ),
      content: (
        <AddOrganization onSubmit={onAdd} onCancel={() => setVisible(false)} onUpdate={setUpates}
          onchangeUpdate={updates} />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Edit Organization
        </Title>
      ),
      content: (
        <AddOrganization
          onSubmit={onAdd}
          selected={selected}
          onCancel={() => setVisible(false)}
          onUpdate={setUpates}
          onchangeUpdate={updates}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader
          heading="Organizations"
          addNewAction={addNew}
          search={onTopSearch}
        />
        <Row gutter={24}>
          <Col span={24}>
            <Table
              className="custom-table"
              columns={columns}
              pagination={{
                current: page,
                pageSize: 50,
                showSizeChanger: false,
                className: "ag-property-text-pagination mb-1",
                position: ["topLeft"],
                onChange: (e) => {
                  setPage(e);
                  dispatch(getOrganizations(e, search))
                },
                total: (organization?.count),
                showTotal: (total, range) =>
                  `Showing ${range[1]}-${total} Organizations`,
              }}
              dataSource={organization?.rows}
              scroll={{ y: 500 }}
            />
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};
