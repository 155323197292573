import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useForm } from "react-hook-form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { adminService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";
import { getRoles } from "../../ducks/actions";

var _ = require("lodash");
const { Paragraph } = Typography;

const defaultValues: any = {
  roleName: "",
  applicationId: "",
};

const AddRole = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, errors, reset, handleSubmit } = useForm({ defaultValues });

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    const { roleName, applicationId } = values;
    const postJson = {
      roleName,
      applicationId: applicationId.value.toString(),
      // description,
    };
    console.log("dkk", postJson);
    const url = `${adminService}/access/createRole`;
    try {
      await axios.post(url, postJson, { headers: { application: 4 } });
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        getRoles(props.page, '')
        reset();
        message.success("Role added successfully");
        props.onSubmit();
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
      console.log("check", response);
    }
  };

  const resetForm = () => {
    reset({ defaultValues });
    props?.setOnClose(false)
  }

  useEffect(() => {
    resetForm()
  }, [props?.onClose])


  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="roleName"
                label="Role Name"
                control={control}
                rules={{
                  required: "Please enter role name",
                  pattern: {
                    value: /^[a-zA-Z._^%$#!~@,-]+$/,
                    message: "Role name should not contain space or numeric values",
                  },
                }}
                iProps={{ placeholder: "Enter role name" }}
                initValue=""
                validate={errors.roleName && "error"}
                validMessage={errors.roleName && errors.roleName.message}
              />
            </Col>

            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="applicationId"
                label="Application"
                control={control}
                rules={{
                  required: "Please select application",
                }}
                iProps={{ placeholder: "Select Application" }}
                selectOption={_.map(props.appIds.rows, (item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                initValue=""
                validate={errors.applicationId && "error"}
                validMessage={
                  errors.applicationId && errors.applicationId.message
                }
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-gray-button-outline"
                htmlType="button"
                onClick={() => {
                  reset({ defaultValues });
                  props.onCancel();
                }}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddRole;
