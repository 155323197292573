import React, { useState, useEffect } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { Card } from "antd";
import EmptyPlaceholder from "../../atoms/EmptyPlaceholder";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

const Grid = (props) => {
  const {
    filterGrid,
    data,
    columns,
    pagination,
    defaultSettings,
    noRowlabel,
    noRowbtnAction,
    refreshAction,
    fullPage,
    getRowHeight,
  } = props;

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [noRow, setNoRow] = useState(false);

  useEffect(() => {
    setRowData(data);
    data?.length ? setNoRow(false) : setNoRow(true);
  }, [data]);

  useEffect(() => {
    gridApi && gridApi.setQuickFilter(filterGrid);
  }, [filterGrid]);

  useEffect(() => {
    refreshAction ? refreshing() : null;
  }, [refreshAction]);
  const refreshing = () => {
    console.log("refresh");
    var params = {
      force: true,
      suppressFlash: true,
      columns: [props.refCol],
    };
    gridApi && gridApi.refreshCells(params);
  };

  function onModelUpdated($event) {
    if (gridApi && gridApi.rowModel.rowsToDisplay.length == 0) {
      gridApi.showNoRowsOverlay();
    }
    if (gridApi && gridApi.rowModel.rowsToDisplay.length > 0) {
      gridApi.hideOverlay();
    }
  }

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(props.columnApi);

    // const httpRequest = new XMLHttpRequest();
    // const updateData = (data) => {
    //   setRowData(data);
    // };

    // httpRequest.open(
    //   "GET",
    //   "https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/olympicWinners.json",
    // );
    // httpRequest.send();
    // httpRequest.onreadystatechange = () => {
    //   if (httpRequest.readyState === 4 && httpRequest.status === 200) {
    //     updateData(JSON.parse(httpRequest.responseText));
    //   }
    // };
  }

  // const onRowDragMove = (event) => {
  //   const movingNode = event.node;
  //   const overNode = event.overNode;
  //   const rowNeedsToMove = movingNode !== overNode;
  //   if (rowNeedsToMove) {
  //     const movingData = movingNode.data;
  //     const overData = overNode.data;
  //     const fromIndex = rowData.indexOf(movingData);
  //     const toIndex = rowData.indexOf(overData);
  //     const newStore = rowData.slice();
  //     moveInArray(newStore, fromIndex, toIndex);
  //     setRowData(newStore);
  //     // immutableStore = newStore;
  //     gridApi.setRowData(newStore);
  //     gridApi.clearFocusedCell();
  //   }
  //   function moveInArray(arr, fromIndex, toIndex) {
  //     const element = arr[fromIndex];
  //     arr.splice(fromIndex, 1);
  //     arr.splice(toIndex, 0, element);
  //   }
  // };

  return (
    <div
      className={`custom-style h-auto ${props.smallGrid ? "small-grid" : ""}`}
    >
      <div
        id="myGrid"
        style={{ height: "350px", width: "100%" }}
        className={`ag-theme-material ${
          fullPage && data.length ? "fp-height" : ""
        } ${noRow ? "h-auto" : ""}`}
      >
        <AgGridReact
          defaultColDef={{
            minWidth: 170,
            flex: 1,
            ...defaultSettings,
            // floatingFilter: true,
          }}
          pagination={pagination}
          // onRowDragMove={onRowDragMove}
          suppressDragLeaveHidesColumns={true}
          animateRows={true}
          columnDefs={columns}
          rowData={rowData}
          onModelUpdated={onModelUpdated}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 1px solid #777;">No Data Found</span>'
          }
          onGridReady={onGridReady}
          getRowHeight={getRowHeight}
        />
      </div>
      {noRow && (
        <Card bordered={false}>
          <EmptyPlaceholder label={noRowlabel} action={noRowbtnAction} />
        </Card>
      )}
    </div>
  );
};

export default Grid;
