import axios from "axios";
import LocalStorageService from "./localStorageService";
import { authRefresh } from '../configs/constants'
// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken();
        const deviceID = localStorageService.getDevieId();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
            config.headers['device_id'] = deviceID;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });



//Add a response interceptor

axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    const originalRequest = error.config;
    // console.log({ originalRequest })
    // if (error.response.status === 401) {
    //     // history.push('/login');
    //     return Promise.reject(error);
    // }

    if (error.response.status === 401 && !originalRequest._retry) {

        originalRequest._retry = true;
        const userInformation = JSON.parse(localStorage.getItem('login-response'))
        return axios.post(`${authRefresh}/user/auth/refresh`,
            {
                "refreshToken": userInformation.refreshToken,
                "username": userInformation.data.username
            })
            .then(res => {
                if (res.status === 200) {
                    const updatedInfo = res?.data.result
                    const updateUserInfo = {
                        ...userInformation,
                        exp: updatedInfo.exp,
                        iat: updatedInfo.iat,
                        refreshToken: updatedInfo.refreshToken,
                        token: updatedInfo.token
                    }
                    const data = JSON.stringify(updateUserInfo)
                    localStorage.setItem('login-response', data);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('login-response')).token;
                    return axios(originalRequest);
                }
            })
    }
    return Promise.reject(error);
});

export default axios;