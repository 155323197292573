import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Layout,
  Avatar,
  Dropdown,
  Menu,
  Image,
  Space,
  Button,
  Badge,
  Typography,
} from "antd";
import {
  UserOutlined,
  SearchOutlined,
  BellOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { actionLogout } from "./duck/actions";
import storeLogo from "../../../assets/img/eqari-logo.svg";
import NotificationPanel from "../NotificationPanel";
import { deleteCookie } from "../../../utils/helper";
import { Link } from "react-router-dom";
import { onLogout } from "../../modules/Login/duck/actions";

const { Header } = Layout;
const { Text } = Typography;

let cachedNotificationLength = 0;

const HeaderBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [seen, setSeen] = useState(true);
  let notificationInterval = null;

  const logoutHandler = () => {
    window.localStorage.clear();
    deleteCookie("landlordUser");
    dispatch(onLogout());
    history.push("/login");
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          onClick={logoutHandler}
          type="link"
          className="btn-link"
          icon={<LogoutOutlined />}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const Notification: any = NotificationPanel;

  return (
    <Header className="site-header">
      <Row>
        <Col flex={200}>
          <Image
            src={storeLogo}
            preview={false}
            width={150}
            alt="EQARCOM logo"
            style={{ marginTop: 5 }}
          />
        </Col>
        <Col flex="auto">
          <Space size={12}>
            {/* <Dropdown
                overlay={<Notification notificationsData={notifications} />}
                placement="bottomRight"
                trigger={["click"]}
                arrow
              >
                <Button
                  type="link"
                  className="headerIcon-btn"
                  size="large"
                  onClick={() => setSeen(false)}
                >
                  <BellOutlined />
                </Button>
              </Dropdown> */}
            <Dropdown overlay={menu} placement="bottomRight">
              <Space>
                <Avatar size={24} icon={<UserOutlined />} />
                <Text>
                  {
                    JSON.parse(window.localStorage.getItem("login-response"))
                      ?.data?.firstName
                  }
                </Text>
              </Space>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderBar;
