import * as action_types from "./constants";

const initialState: any = {
  rolesData: [],
  appData: {},
  applicationData: [],
  organizationData: [],
  permissionData: [],
  usersData: [],
  userApplicationRoles: []
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case action_types.GET_ROLES:
      return { ...state, rolesData: data };
    case action_types.GET_APPLICATIONS:
      return { ...state, appData: data };
    case action_types.GET_APPLICATION_ROLES:
      return { ...state, applicationData: data };
    case action_types.GET_ORGANIZATION_LIST:
      return { ...state, organizationData: data };
    case action_types.GET_PERMISSION_APPLICATIONS:
      return { ...state, permissionData: data }
    case action_types.GET_USERS:
      return { ...state, usersData: data };
    case action_types.GET_USERS_APPLICATION_ROLES:
      return { ...state, userApplicationRoles: data };
    default:
      return state;
  }
};
