import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { adminService } from "../../../../configs/constants";
const assignedBaseURL = `${adminService}/admin/getAllAssignedTechnicians`;
const unassignedBaseURL = `${adminService}/admin/getAllUnassignedTechnicians`;
const zoneUrl = `${adminService}/admin/getAllZones`;

export const getAssignedList = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(assignedBaseURL + `?perPage=50&page=${page}`);
      dispatch({
        type: action_types.GET_TECH_ASSIGNED_LIST,
        data: result,
      });
      dispatch({
        type: action_types.GET_TECH_ASSIGNED_COUNT,
        data: result.count,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getUnAssignedList = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(unassignedBaseURL + `?perPage=50&page=${page}`);
      dispatch({
        type: action_types.GET_TECH_UNASSIGNED_LIST,
        data: result,
      });
      dispatch({
        type: action_types.GET_TECH_UNASSIGNED_COUNT,
        data: result.count,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getZones = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(zoneUrl);
      dispatch({
        type: action_types.GET_ZONE_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
