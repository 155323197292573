import { combineReducers } from "redux";
import { LOGOUT_SUCCESS } from "../../app/modules/Login/duck/constants";
import auth from "../../app/modules/Login/duck/reducer";
import signup from "../../app/modules/SignUp/duck/reducer";
import header from "../../app/molecules/Header/duck/reducer";
import facility from "../../app/modules/FacilityManager/ducks/reducers";
import supervisor from "../../app/modules/Supervisors/ducks/reducers";
import dashboard from "../../app/modules/AllProperties/ducks/reducers";
import addInfoReducer from "../../app/modules/AllProperties/AddProperty/ducks/reducers/addInfoReducer";
import configuration from "../../app/modules/Configurations/ducks/reducers";
import roles from "../../app/modules/RoleAndAccess/ducks/reducers";
import cms from "../../app/modules/Cms/ducks/reducers";
import announcement from "../../app/modules/Announcement/ducks/reducers";
import organization from "../../app/modules/Organizations/ducks/reducers";
import tenants from "../../app/modules/Tenants/ducks/reducers";
import contracts from "../../app/modules/PostDatedCheques/ducks/reducers";
import technician from "../../app/modules/Technicians/ducks/reducers";
const appReducer = combineReducers({
  auth,
  header,
  signup,
  facility,
  supervisor,
  dashboard,
  addInfoReducer,
  configuration,
  roles,
  cms,
  announcement,
  organization,
  tenants,
  contracts,
  technician,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === LOGOUT_SUCCESS) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
