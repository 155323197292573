import React, { useState, useEffect } from "react";
import { Button, Row, Card, Space, Col, Form, Typography, message } from "antd";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { InputField, SelectField } from "../../../atoms/FormElement";
import BreadCrumb from "../../../atoms/BreadCrumb";
import { getSpecificDetail } from "../ducks/actions";
import axios from "../../../../utils/axiosInceptor";
import { adminService } from "../../../../configs/constants";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory } from "react-router-dom";
const breadcrumbList: any = [
  {
    label: "Template Details",
  },
];

const defaultValues: any = {
  key: "",
  type: "",
  role: "",
  subject: "",
  footer: "",
};

const { Paragraph, Title } = Typography;

const ViewTemplate = () => {
  const [editorData, setEditorData] = useState("");
  const [type, setType] = useState();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const specficDetail = useSelector(
    (state) => state.configuration.specficDetail,
  );
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const typeList = [
    { value: "email", label: "Email" },
    { value: "push_notification", label: "Push Notification" },
    { value: "sms", label: "SMS" },
  ];

  useEffect(() => {
    const type = location.search.substring(1);
    const typeObj = type.split("=");
    setType(typeObj[1]);
    params?.id && dispatch(getSpecificDetail(params.id, typeObj[1]));
  }, []);

  useEffect(() => {
    if (Object.keys(specficDetail).length > 0) {
      setValue("key", specficDetail?.key.replace(`_${type}`, ""));
      setValue(
        "type",
        typeList.find((x) => x.value == type),
      );
      setValue("subject", specficDetail?.subject);
      setValue("footer", specficDetail?.footer);
      const contentblock = htmlToDraft(specficDetail.body);
      let x: any = false;
      contentblock.contentBlocks.map((y) => {
        if (y.depth == undefined) {
          x = true;
        }
      });
      if (x === false) {
        const state = EditorState.createWithContent(
          ContentState.createFromBlockArray(contentblock.contentBlocks),
        );
        const stateData = draftToHtml(convertToRaw(state.getCurrentContent()));

        setEditorState(state);
        setEditorData(stateData);
      } else {
        setEditorState(EditorState.createEmpty());
        setEditorData("");
      }
    }
  }, [specficDetail]);

  const onEditorStateChange = (editorState) => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorData(data);
    setEditorState(editorState);
  };

  const onFinish = async (values) => {
    const body = {
      key: `${values.key}_${values.type.value}`,
      type: values.type.value,
      subject: values.subject,
      body: editorData,
      footer: values.footer || "",
    };

    const url = `${adminService}/notification/${specficDetail.templateId}`;
    try {
      await axios.put(url, body, { headers: { application: 4 } });
      message.success("updated successfully");
      setTimeout(() => {
        history.push("/configurations");
      }, 1500);
    } catch (e) {
      const { response } = e;
      message.error(response?.data?.message);
    }
  };

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb
            name="Configuration"
            link="/configurations"
            list={breadcrumbList}
          />
        </Col>
        <Col span={24}>
          <Card
            bordered={false}
            bodyStyle={{ padding: "32px 32px" }}
            className="h-auto"
          >
            <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
              <Row gutter={24}>
                <Col span={24}>
                  <Title level={3}>Template Details</Title>
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    fieldname="key"
                    label="Key Name"
                    control={control}
                    className="blue-label"
                    rules={{
                      required: "Please enter Key name",
                    }}
                    iProps={{ placeholder: "LEASE_FORM_REJECTED" }}
                    initValue=""
                    validate={errors.key && "error"}
                    validMessage={errors.key && errors.key.message}
                  />
                </Col>
                <Col span={8}>
                  <SelectField
                    isRequired={true}
                    fieldname="type"
                    label="Type"
                    control={control}
                    validate={errors.type && "error"}
                    validMessage={errors.type && errors.type.message}
                    iProps={{ placeholder: "Please Select", isDisabled: true }}
                    rules={{ required: "Please Select" }}
                    initValue=""
                    selectOption={typeList}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    fieldname="subject"
                    label="Subject"
                    className="blue-label"
                    control={control}
                    rules={{
                      required: "Please enter Subject ",
                    }}
                    initValue=""
                    validate={errors.subject && "error"}
                    validMessage={errors.subject && errors.subject.message}
                  />
                </Col>
                <Col span={24}>
                  <Paragraph className="ag-primary ">Body:</Paragraph>
                  <Editor
                    editorState={editorState}
                    wrapperClassName="editor-main"
                    editorClassName="editor-body"
                    onEditorStateChange={onEditorStateChange}
                  />
                </Col>
                <Col span={24}>
                  <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Space size={12}>
                      <Button>#Name</Button>
                      <Button>#Email</Button>
                      <Button>#ContactNo</Button>
                    </Space>
                  </Row>
                </Col>
                <Col span={24}>
                  <InputField
                    fieldname="footer"
                    label="Footer"
                    control={control}
                    className="blue-label"
                    initValue=""
                    validate={errors.footer && "error"}
                    validMessage={errors.footer && errors.footer.message}
                  />
                </Col>
                <Col span={24}>
                  <Row justify="space-between">
                    <Button
                      size="large"
                      onClick={() => history.push("/configurations")}
                    >
                      Cancel
                    </Button>
                    <Button htmlType="submit" type="primary" size="large">
                      Update
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default ViewTemplate;
