import * as action_types from "./constants";

const initialState: any = {
  unassignedList: [],
  assignedList: [],
  unassignedCount: 0,
  assignedCount: 0,
  zones: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case action_types.GET_TECH_ASSIGNED_LIST:
      return { ...state, assignedList: data };
    case action_types.GET_TECH_UNASSIGNED_LIST:
      return { ...state, unassignedList: data };
    case action_types.GET_TECH_UNASSIGNED_COUNT:
      return { ...state, unassignedCount: data };
    case action_types.GET_TECH_ASSIGNED_COUNT:
      return { ...state, assignedCount: data };
    case action_types.GET_ZONE_LIST:
      return { ...state, zones: data };
    default:
      return state;
  }
};
