import * as action_types from "./constants";

const initialState: any = {
  categoryRoot: [],
  childCategory:[],
  notifList:[],
  specficDetail:{}
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case action_types.GET_CATEGORY_ROOT:
      return { ...state, categoryRoot: data };
    case action_types.GET_CATEGORY_CHILD:
      return { ...state, childCategory: data };
    case action_types.GET_NOTIFICATION:
      return { ...state, notifList: data };
    case action_types.GET_SPECIFIC_DETAIL:
      return { ...state, specficDetail: data };
    case action_types.GET_TIMESLOTS_LIST:
      return {...state,timeslotsList:data}
    case action_types.GET_TIMESLOTS_TYPE:
      return {...state,timeSlotsType:data}       
    default:
      return state;
  }
};
