export const SITEKEY = "6LfyGo8bAAAAAPNwvOlUiLfMelq9dII867qVxKvs";
export const SECRETKEY = "6LfyGo8bAAAAAAj78sbeF8l4ShR-WPmN0Lkt4-ND";

// export const baseUrl = "https://eqari-dev-api.agp-dev.com";
// export const propertyService = baseUrl + "/api/v1";
// export const leaseService = baseUrl + "/lead-to-lease/api/v1";
// export const authService = baseUrl + "/auth/api/v1";
// export const maintenanceService = baseUrl + "/maintenance/api/v1";
// export const s3BucketUrl =
//     "https://ekari-media-dest-dev.s3.ap-south-1.amazonaws.com/ekari";
// export const adminService = baseUrl + "/admin/api/v1";
//export const authRefresh = baseUrl + "/auth/api/v1"

// QA endpoint
// export const baseUrl = "https://eqari-dev-api.agp-dev.com";
// export const propertyService = baseUrl + "/qa-propety/api/v1";
// export const leaseService = baseUrl + "/qa-lead-to-lease/api/v1";
// export const authService = baseUrl + "/qa-auth/api/v1";
// export const maintenanceService = baseUrl + "/qa-auth/maintenance/api/v1";
// export const s3BucketUrl =
//   "https://eqari-qa-media.s3.ap-south-1.amazonaws.com/ekari";
// export const adminService = baseUrl + "/qa-admin/api/v1";
// export const authRefresh = baseUrl + "/qa-auth/api/v1";

// Staging
// export const baseUrl = "https://eqari-staging-api.agp-dev.com";
// export const propertyService = baseUrl + "/api/v1";
// export const leaseService = baseUrl + "/lead-to-lease/api/v1";
// export const authService = baseUrl + "/auth/api/v1";
// export const maintenanceService = baseUrl + "/maintenance/api/v1";
// export const s3BucketUrl =
//   "https://ekari-media-dest-dev.s3.ap-south-1.amazonaws.com/ekari";
// export const adminService = baseUrl + "/admin/api/v1";
// export const authRefresh = baseUrl + "/auth/api/v1";

// Production
// export const baseUrl = "https://prod-api.agp-dev.com";
// export const propertyService = baseUrl + "/api/v1";
// export const leaseService = baseUrl + "/lead-to-lease/api/v1";
// export const authService = baseUrl + "/auth/api/v1";
// export const maintenanceService = baseUrl + "/maintenance/api/v1";
// export const s3BucketUrl =
//   "https://eqari-media-bucket-production.s3.ap-south-1.amazonaws.com/ekari";
// export const adminService = baseUrl + "/admin/api/v1";
// export const authRefresh = baseUrl + "/auth/api/v1";

export const baseUrl = process.env.API_URL;
export const extra = process.env.EXTRA;
export const addition = process.env.ADDITON;
export const bucketurl = process.env.BUCKET;

export const propertyService = baseUrl + extra + "api/v1";
export const leaseService = baseUrl + addition + "lead-to-lease/api/v1";
export const authService = baseUrl + addition + "auth/api/v1";
export const maintenanceService = baseUrl + addition + "maintenance/api/v1";
export const adminService = baseUrl + addition + "admin/api/v1";
export const authRefresh = baseUrl + addition + "auth/api/v1";
export const s3BucketUrl = bucketurl;
