import React, { useState, useEffect } from "react";
import { Radio, Col, Row, Space, Table, Typography, message, Card } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from "../../../molecules/DashboardLayout";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { getpermissionApplications, getContentCategories } from '../ducks/actions'
import { getAppRolesAndApplication, getSubmoduleRoles, updateRolePermission } from '../ducks/services'
import { Collapse } from 'antd';


const RolePermissions = (props) => {
  const { Panel } = Collapse;
  const { Title } = Typography;
  const [type, setType] = useState(1);
  const [roletype, setRoletype] = useState<any>('')
  const [loader, setLoader] = useState(false)
  const [loader2, setLoader2] = useState(false)
  const [permissionRoles, setpermissionRoles] = useState<any>()
  const [subModuleRole, setsubModuleRole] = useState<any>()
  const [subModule, setsubModule] = useState<any>()
  const [permission, setpermissions] = useState<any>([])
  const [modRoles, setmodRoles] = useState<any>([])
  const [panalIndex, setpanalIndex] = useState(0)
  const dispatch = useDispatch()
  const [activeKye, setActiveKey] = useState('')
  const accessPermission = useSelector((state) => state.roles.permissionData)
  const accessRoles = useSelector((state) => state.roles.applicationData)

  const columns: any = [
    {
      title: "Create",
      dataIndex: "CREATE",
      key: "CREATE",
      render: (value, record, rowIndex) => {
        return <Checkbox key={record} checked={value} onChange={onSubModuleChangeHandler(rowIndex, 'CREATE', record)} />
      },
    },
    {
      title: "Read",
      dataIndex: "READ",
      key: "READ",
      render: (value, record, rowIndex) => {
        return <Checkbox key={rowIndex} checked={value} onChange={onSubModuleChangeHandler(rowIndex, 'READ', record)} />
      },
    },
    {
      title: "Update",
      dataIndex: "UPDATE",
      key: "UPDATE",
      render: (value, record, rowIndex) => {
        return <Checkbox key={rowIndex} checked={value} onChange={onSubModuleChangeHandler(rowIndex, 'UPDATE', record)} />
      },
    },
    {
      title: "Delete",
      dataIndex: "DELETE",
      key: "DELETE",
      render: (value, record, rowIndex) => {
        return <Checkbox key={rowIndex} checked={value} onChange={onSubModuleChangeHandler(rowIndex, 'DELETE', record)} />
      },
    },
  ];
  const columns1: any = [
    {
      title: 'Roles',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: "Create",
      dataIndex: "CREATE",
      key: "CREATE",
      render: (value, record, rowIndex) => {
        // record.subModData = record.subModData[record.subModData.length - 1]
        let create = true
        if (record.subModData[panalIndex].CREATE !== undefined && record.CREATE !== undefined) {
          create = false
        }
        return <Checkbox key={rowIndex} checked={value} onChange={onSubModuleRoleHanlder(rowIndex, 'CREATE', record)} disabled={create} />
      },
    },
    {
      title: "Read",
      dataIndex: "READ",
      key: "READ",
      render: (value, record, rowIndex) => {
        let read = true
        if (record.subModData[panalIndex].READ !== undefined && record.READ !== undefined) {
          read = false
        }
        return <Checkbox key={rowIndex} checked={value} onChange={onSubModuleRoleHanlder(rowIndex, 'READ', record)} disabled={read} />
      },
    },
    {
      title: "Update",
      dataIndex: "UPDATE",
      key: "UPDATE",
      render: (value, record, rowIndex) => {
        let up = true
        if (record.subModData[panalIndex].UPDATE !== undefined && record.UPDATE !== undefined) {
          up = false
        }
        return <Checkbox key={rowIndex} checked={value} onChange={onSubModuleRoleHanlder(rowIndex, 'UPDATE', record)} disabled={up} />
      },
    },
    {
      title: "Delete",
      dataIndex: "DELETE",
      key: "DELETE",
      render: (value, record, rowIndex) => {
        let del = true
        if (record.subModData[panalIndex].DELETE !== undefined && record.DELETE !== undefined) {
          del = false
        }
        return <Checkbox key={rowIndex} checked={value} onChange={onSubModuleRoleHanlder(rowIndex, 'DELETE', record)} disabled={del} />
      },
    },
  ];


  useEffect(() => {
    dispatch(getpermissionApplications())
    dispatch(getContentCategories())
  }, [])

  useEffect(() => {

    if (accessRoles.length) {
      accessRoles.filter((value) => {
        if (value.id === type) {
          setpermissionRoles(accessRoles.filter((value) => value.id === type))
        }
      })
    }
  }, [accessRoles])

  useEffect(() => {
    subModule && subModule.map((value, key) => {
      var object = value.perissions.reduce(
        (obj, item) => {
          if (item.to_date === null) {
            item.to_date = true
          }
          else {
            item.to_date = false
          }
          return Object.assign(obj, {
            id: key,
            applicationAssetId: subModule[key].id,
            rowData: subModule[key],
            [item.name]: item.to_date,
          })
        }, {})
      setpermissions(permission => [...permission, object])

    })
  }, [subModule])

  useEffect(() => {
    subModuleRole && subModuleRole.map((value, key) => {
      var object = value.perissions.reduce(
        (obj, item) => {
          if (item.to_date === null) {
            item.to_date = true
          }
          else {
            item.to_date = false
          }
          return Object.assign(obj, {
            id: key,
            data: subModuleRole[key],
            applicationAssetId: subModuleRole[key].id,
            applicationRoleId: subModuleRole[key].type.id,
            type: item.name,
            name: subModuleRole[key].name,
            [item.name]: item.to_date,
            subModData: permission
          })
        }, {})
      setmodRoles(modRoles => [...modRoles, object])
    })
  }, [subModuleRole])


  const onTagChangeHandler = (e) => {
    setRoletype('')
    setType(e.id)
    setsubModule([])
    setpermissionRoles(accessRoles.filter((value) => value.name === e.name))
  }

  const onRoleChangeHandler = (rid, subModID?) => {
    let modId;
    setpermissions([])
    setRoletype(rid)
    if (!subModID) {
      setActiveKey('')
    }
    setLoader2(true)
    setLoader(true)
    getAppRolesAndApplication(type, rid.id).then((response) => {
      if (response?.data?.result) {
        modId = response?.data?.result[0].id
        setsubModule(response.data.result)
        setLoader2(false)
      }
    }).then(() => {
      if (subModID) {
        setmodRoles([])
        getSubmoduleRoles(type, rid.id, subModID).then((response) => {
          if (response?.data?.result) {
            setsubModuleRole(response.data.result)
            setLoader(false)
          }
        }).catch((error) => {
          setLoader(false)
        })
      }
    }).catch((error) => {
      message.error(error?.response?.data?.message)
      setsubModule([])
    })
  }

  const lowerCaseAllWordsExceptFirstLetters = (string) => {
    return string.replace(/\S*/g, function (word) {
      return word.charAt(0) + word.slice(1).toLowerCase();
    });
  }

  const onSubModuleChangeHandler = (rowIndex, columnKey, record) => event => {
    var checkBoxType = lowerCaseAllWordsExceptFirstLetters(columnKey)
    const postData = {
      applicationAssetId: record.applicationAssetId,
      applicationRoleId: roletype.id,
      type: checkBoxType
    }
    updateRolePermission(postData).then((response) => {
      message.success(response?.data?.result)
    }).then(() => {
      onRoleChangeHandler(roletype, record.applicationAssetId)
    })
    const newCheckboxState = [...permission];
    newCheckboxState[rowIndex][columnKey] = event.target.checked;
    setpermissions(newCheckboxState);
  };

  const onSubModuleRoleHanlder = (rowIndex, columnKey, record) => event => {
    var checkBoxType = lowerCaseAllWordsExceptFirstLetters(record.type)
    const postData = {
      applicationAssetId: record.applicationAssetId,
      applicationRoleId: roletype.id,
      type: checkBoxType
    }
    updateRolePermission(postData).then((response) => {
      message.success(response?.data?.result)
    })
    const newCheckboxState = [...modRoles];
    newCheckboxState[rowIndex][columnKey] = event.target.checked;
    setmodRoles(newCheckboxState);
  };

  const onPanalChange = (e) => {
    setLoader(true)
    setpanalIndex(e)
    setActiveKey(e)
    setmodRoles([])
    if (subModule[e]?.id) {
      getSubmoduleRoles(type, roletype.id, subModule[e].id).then((response) => {
        if (response?.data?.result) {
          setsubModuleRole(response.data.result)
          setLoader(false)
        }
      }).catch((error) => {
        message.error(error?.response?.data?.message)
        setsubModule([])
      })
    }

  }


  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Title level={2} className="m-0">
            Permissions
          </Title>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card title={<Title level={3}>Applications</Title>}>
            <Radio.Group
              defaultValue={'LandlordApp'}
              buttonStyle="solid"
              size={'large'}
            >
              <Space size={12}>
                {accessPermission?.rows && accessPermission.rows.map((value, key) => {
                  return (
                    <Radio.Button
                      key={key}
                      value={value.name}
                      onClick={() => onTagChangeHandler(value)}
                    >
                      {value.name}
                    </Radio.Button>
                  )
                })}
              </Space>
            </Radio.Group>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card title={<Title level={3}>Roles</Title>}>
            <Radio.Group
              buttonStyle="solid"
              size={'large'}
              value={roletype.name}
            >
              <Row gutter={[24, 24]}>
                {permissionRoles && permissionRoles[0].ApplicationRole.map((value, key) => {
                  return (
                    <Col key={key}>
                      <Radio.Button
                        key={key}
                        value={value.name}
                        onClick={() => onRoleChangeHandler(value)}
                      >
                        {value.name}
                      </Radio.Button>
                    </Col>
                  )
                })}
              </Row>
            </Radio.Group>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>

          <Collapse
            accordion
            expandIconPosition="right"
            className="ag-collapse ag-border-card"
            onChange={(e) => onPanalChange(e)}
            activeKey={activeKye}
          >
            {subModule && subModule.map((value, key) => {
              return (

                <Panel header={`${value.name}`} key={key}>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <Table
                        className="custom-table"
                        columns={columns}
                        dataSource={[permission[key]]}
                        pagination={false}
                        loading={loader2}
                      />
                    </Col>
                    <Col span={24}>
                      <Table className="custom-table"
                        columns={columns1}
                        dataSource={modRoles}
                        pagination={false}
                        loading={loader}
                      >
                      </Table>
                    </Col>
                  </Row>
                </Panel>
              )
            })}
          </Collapse>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default RolePermissions;
