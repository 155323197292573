import axios from '../../../../utils/axiosInceptor'
import { adminService } from '../../../../configs/constants'

const app_role = `${adminService}/access/getSubModuleRolesAndPermission`
const submodule_role = `${adminService}/access/getApisRolesAndPermission`

export const getAppRolesAndApplication = (appID, roleID) => {
    return axios.get(`${app_role}?applicationRoleId=${roleID}&applicationId=${appID}`)
}

export const getSubmoduleRoles = (appID, roleID, subModID) => {
    return axios.get(`${submodule_role}?applicationRoleId=${roleID}&applicationId=${appID}&subModuleId=${subModID}`)
}

export const updateRolePermission = (data) => {
    return axios.put(`${adminService}/access/update-permission`, data)
}