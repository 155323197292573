import {
  Button,
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../atoms/BreadCrumb";
import DashboardLayout from "../../../molecules/DashboardLayout";
import SectionHeader from "../../../molecules/SectionHeader";
import Grid from "../../../atoms/Grid";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
// import {getUsersList} from './ducks/actions';
import { getUsersList, getusersRolesApplication } from "../ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "../../../atoms/Popup";
import AddUser from "../components/AddUser";
import { useParams } from "react-router-dom";

const { Title, Text } = Typography;
var _ = require("lodash");

const UserList = (props) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const usersList = useSelector((state) => state.roles.usersData.rows);
  //const usersList = useSelector((state) => state.roles.usersData);
  const { Option } = Select;
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [onClose, setOnClose] = useState(false);
  const [popUpData, setPopUpData] = useState({});
  const [IDs, setIDs] = useState(1);
  const announcemnetCount = useSelector((state) => state.roles.usersData.count);
  const paramsID = useParams();

  const breadcrumbList: any = [
    {
      label: "Roles & Access Management",
      route: "/roles-access-management",
    },
    {
      label: "Users",
      route: `/user-list/${paramsID?.id}`,
    },
  ];

  useEffect(() => {
    dispatch(getUsersList(paramsID?.id, page, searchText));
  }, []);

  const fetchData = (e) => {
    if (e >= 2) {
      if (e > page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      } else if (e < page) {
        setIDs((e - 1) * 50 + 1);
        setPage(e);
      }
    } else {
      setIDs(e);
      setPage(e);
    }

    dispatch(getUsersList(paramsID?.id, e, searchText));
  };

  const propertiesComponent = (params) => {
    return (
      <Select
        className="Sentence"
        style={{ width: 250 }}
        placeholder="Select to view"
        dropdownRender={(menu) => <>{menu}</>}
      >
        <React.Fragment>
          {params.data.roles.map((item) => {
            return (
              <Select.Option
                key={item.id}
                value={item.id}
                className="Sentence active-select"
              >
                {item.name}
              </Select.Option>
            );
          })}
        </React.Fragment>
      </Select>
    );
  };

  const actionEditDelete = (params) => {
    return (
      <>
        <Space size={16} style={{ textAlign: "center" }}>
          <Button
            type="link"
            className="p-0 ag-success"
            onClick={() => updateUser(params.data)}
          >
            <EditOutlined />
          </Button>
        </Space>
      </>
    );
  };

  const actionsDropdown = (params) => {
    const userAppRoles = useSelector(
      (state) => state.roles.userApplicationRoles,
    );
    return (
      <Select
        className="Sentence"
        style={{ width: "100%" }}
        placeholder="Select to view"
        key={params.data.application_user_id}
        onDropdownVisibleChange={() =>
          dispatch(getusersRolesApplication(params.data.application_user_id))
        }
      >
        {_.map(userAppRoles, (d, index) => (
          <Option key={index} value={d.id}>
            {d.applicationName}
          </Option>
        ))}
      </Select>
    );
  };

  const getEmail = (params) => {
    return (
      <>
        {params.data?.contact_info?.electronic_address &&
          params.data?.contact_info?.electronic_address.map(
            (item) => item?.email,
          )}
      </>
    );
  };

  const RolesDropdown = (params) => {
    const userAppRoles = useSelector(
      (state) => state.roles.userApplicationRoles,
    );
    return (
      <Select
        className="Sentence"
        style={{ width: "100%" }}
        placeholder="Select to view"
        key={params.data.application_user_id}
        onDropdownVisibleChange={() =>
          dispatch(getusersRolesApplication(params.data.application_user_id))
        }
      >
        {_.map(userAppRoles, (d, index) => (
          <Option key={index} value={d.id}>
            {d.ApplicationRoles[0].role}
          </Option>
        ))}
      </Select>
    );
  };

  const combineNames = (param) => {
    return (
      <span>
        {param.data.first_name && param.data.first_name}{" "}
        {param.data.last_name && param.data.last_name}{" "}
      </span>
    );
  };

  const column = [
    {
      headerName: "Id",
      valueGetter: `${IDs}+node.rowIndex`,
      minWidth: 40,
      maxWidth: 80,
      pinned: "left",
    },
    { headerName: "Name", field: "name", cellRendererFramework: combineNames },
    {
      headerName: "Application(s)",
      field: "application",
      cellRendererFramework: actionsDropdown,
    },
    {
      headerName: "Role(s)",
      field: "roles",
      cellRendererFramework: RolesDropdown,
    },
    { headerName: "Organization", field: "organisation" },
    {
      headerName: "Email Address",
      field: "emailAddress",
      cellRendererFramework: getEmail,
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRendererFramework: actionEditDelete,
      maxWidth: 180,
    },
  ];

  const onSearch = (e) => {
    setSearchText(e);
    dispatch(getUsersList(paramsID?.id, page, e));
  };

  const searchField = <Input.Search onSearch={onSearch} placeholder="Search" />;

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Edit User
        </Title>
      ),
      content: (
        <AddUser
          data={popUpData}
          popUpClose={setVisible}
          editMode={true}
          onClose={onClose}
          setOnClose={setOnClose}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
    ,
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[0].title,
    content: popup[0].content,
    width: popup[0].width,
    onCancel: () => onCancel(),
  };

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
    setPopUpData({});
  };

  const updateUser = (e) => {
    setPopUpData(e);
    setVisible(true);
  };

  return (
    <>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>

        <SectionHeader heading="Users" noborder={true} />
        <Row justify="end" className="mt-1 mb-1">
          <Pagination
            style={{ width: "100%" }}
            className="ag-property-text-pagination"
            current={page}
            total={announcemnetCount && announcemnetCount}
            onChange={fetchData}
            pageSize={50}
            showSizeChanger={false}
            showTotal={(total, range) => `Showing ${range[1]} of ${total}`}
          />
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card className="ag-nospace-body" extra={searchField}>
              <Grid data={usersList} columns={column} pagination={false} />
            </Card>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};

export default UserList;
