import React, { useState, useEffect } from "react";
import { Button, Row, Col, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AddMaintanence from "./Modals/AddMaintenance";
import AddSubType from "./Modals/AddSubType";
import UpdateServices from "./Modals/UpdateServices";
import Panel from "../../../molecules/Panel";
import { Popup } from "../../../atoms/Popup";
import { getCategoryRoots, getCategoryChild } from "../ducks/actions";

const data = [
  {
    id: 1,
    title: "Plumbing",
  },
  {
    id: 2,
    title: "Electrical",
  },
  {
    id: 3,
    title: "Tv and Communication",
  },
  {
    id: 4,
    title: "Air Conditioner",
  },
];

const { Title } = Typography;

const Services = () => {
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [childDetail, setChildDetails] = useState();
  const [parentID, setParentID] = useState();
  const categoryRoot = useSelector((state) => state.configuration.categoryRoot);
  const childData = useSelector((state) => state.configuration.childCategory);
  const [onClose, setOnClose] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoryRoots());
  }, []);

  const showModal = (param) => {
    setTrigger(param);
    setVisible(true);
  };

  const filterData = () => {
    if (categoryRoot && categoryRoot?.rows)
      return categoryRoot.rows.map((index) => ({
        id: index.id,
        title: index.display_name,
      }));
  };

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Update Maintenance Type
        </Title>
      ),
      content: (
        <UpdateServices
          setVisible={setVisible}
          childDetail={childDetail}
          type="category"
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Update Maintenance Sub-Type
        </Title>
      ),
      content: (
        <UpdateServices
          childDetail={childDetail}
          parentID={parentID}
          setVisible={setVisible}
          type="subtype"
        />
      ),
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add Maintenance Type
        </Title>
      ),
      content: (
        <AddMaintanence
          onClose={onClose}
          setOnClose={setOnClose}
          visible={visible}
          setVisible={setVisible}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add Maintenance Sub-Type
        </Title>
      ),
      content: (
        <AddSubType
          onClose={onClose}
          setOnClose={setOnClose}
          setVisible={setVisible}
          category={filterData()}
        />
      ),
    },
  ];

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
  };
  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => onCancel(),
  };

  const filterSubData = () => {
    console.log("childData", childData);
    if (childData && childData?.childs)
      return childData.childs.map((index) => ({
        id: index.id,
        title: index.display_name,
      }));
  };

  const getItemDetails = (item, type) => {
    if (type === "Maintenance Types") {
      dispatch(getCategoryChild(item?.id));
      setParentID(item?.id);
    }
  };

  const getCurrentSelect = (item) => setChildDetails(item);

  return (
    <>
      <Row gutter={24}>
        <Col xl={24}>
          <Row gutter={24}>
            <Col span={12}>
              <Panel
                ticketlist={filterData()}
                setVisible={setVisible}
                setTrigger={setTrigger}
                getItemDetails={getItemDetails}
                getCurrentSelect={getCurrentSelect}
                category="Maintenance Types"
                count={categoryRoot?.rows ? categoryRoot.count : 0}
                panelName="maintenance"
              />
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => showModal(2)}
                  size="large"
                >
                  Add Maintenance Type
                </Button>
              </Row>
            </Col>
            <Col span={12}>
              <Panel
                ticketlist={filterSubData()}
                setVisible={setVisible}
                setTrigger={setTrigger}
                category="Sub-Types"
                count={childData?.childs ? childData.childs.length : 0}
                getItemDetails={getItemDetails}
                getCurrentSelect={getCurrentSelect}
                panelName="sub_type"
              />
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => showModal(3)}
                  size="large"
                >
                  Add Sub-Type
                </Button>
              </Row>
            </Col>
            {/* <Col span={8}>
              <Panel ticketlist={data} category="Time Slots" count={6} />
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Popup {...popupProps} />
    </>
  );
};

export default Services;
