import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useForm } from "react-hook-form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { InputField } from "../../../atoms/FormElement";
import { adminService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";

const { Paragraph } = Typography;

const defaultValues: any = {
  name: "",
  description: "",
};

export default (props) => {
  const { onUpdate, onchangeUpdate } = props
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, errors, reset, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (props.selected) {
      setValue("name", props.selected.name, { shouldValidate: true });
      // setValue("description", props.selected.description, {
      //   shouldValidate: true,
      // });
    } else {
      reset();
    }
  }, [props.selected]);

  const onFinish = (values) => {
    props.onSubmit();
    reset();
    setLoader(true);
    setSubmited(true);
    let postJson;
    if (props.selected?.id) {
      postJson = {
        id: props.selected.id,
        name: values.name,
        // description: values.description,
      };
      const url = `${adminService}/organization/updateOrganization`;
      axios.put(url, postJson, { headers: { application: 4 } }).then((response) => {
        console.log({ response })
        setLoader(false);
        setSubmited(false);
        reset();
        message.success(response.data.message);
        onUpdate(!onchangeUpdate)
        props.onSubmit();
      }).catch((error) => {
        message.error(error.response.data.message)
        setLoader(false);
        setSubmited(false);
        reset();
      })

    }
    else {
      postJson = {
        name: values.name,
        // description: values.description,
      }
      console.log({ postJson })
      const url = `${adminService}/organization/createOrganization`;

      axios.post(url, postJson, { headers: { application: 4 } }).then((response) => {
        setLoader(false);
        setSubmited(false);
        reset();
        message.success(response.data.message);
        onUpdate(!onchangeUpdate)
        props.onSubmit();
      }).catch((error) => {
        message.error(error.response.data.message)
        setLoader(false);
        setSubmited(false);
        reset();
      })
    }

  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Organization Name"
                control={control}
                rules={{
                  required: "Please enter organization name",
                  // pattern: {
                  //   value: /(?=^.{1,50}$)^\S*$/,
                  //   message: "Input role name without space!",
                  // },
                }}
                iProps={{ placeholder: "Enter name" }}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>

            {/* <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="description"
                label="Description"
                control={control}
                iProps={{ placeholder: "Enter description" }}
                initValue=""
                rules={{ required: "Please enter description" }}
                validate={errors.description && "error"}
                validMessage={errors.description && errors.description.message}
              />
            </Col> */}
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error"
                htmlType="button"
                onClick={() => {
                  !props.selected && reset();
                  props.onCancel();
                }}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                {props.selected ? 'Update' : 'Add'}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};
