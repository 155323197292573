/* eslint-disable react/display-name */
import React, { useRef, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Typography, Form, Button, Popover, message } from "antd";
import {
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
// import { updateFormValues } from "./duck/actions";
import { setCookie } from "../../../utils/helper";
import LoginTemplate from "./LoginTemplate";
import axios from "axios";
import { authService } from "../../../configs/constants";
// import thumbIcon from "../../../assets/img/thumb.svg";
// import facebookIcon from "../../../assets/img/facebook.svg";
// import googleIcon from "../../../assets/img/google.svg";
import { useForm } from "react-hook-form";
import { InputField, InputPassword } from "../../atoms/FormElement";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";

const { Text, Paragraph } = Typography;

export default () => {
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginStatus1, setLoginStatus1] = useState(false);
  const [tokenCapcha, setReCapcha] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  let token = JSON.parse(window.localStorage.getItem("login-response"))?.token;
  const refReCaptcha = useRef(null);

  // const { username, password } = useSelector(
  //   (state) => ({
  //     username: state.auth.username,
  //     password: state.auth.password,
  //     token: state.auth.token,
  //   }),
  //   shallowEqual,
  // );
  // const dispatch = useDispatch();
  const history = useHistory();

  // const changeHandler = (e: any) => {
  //   console.log("checkign -----", e);
  //   const { name, value } = e.target;
  //   dispatch(updateFormValues(name, value));
  // };

  const verifyCallback = (token) => {
    // console.log("recaptch", token);
    setReCapcha(token);
  };

  React.useEffect(() => {
    loadReCaptcha(SITEKEY);
    token && history.push("/dashboard");
  }, []);

  const { control, handleSubmit, errors, setValue } = useForm();

  const onFinish = async (values) => {
    setLoginStatus(false);
    setLoading(true);
    const url = `${authService}/user/auth`;

    const postJson = {
      username: values.username,
      password: values.password,
      recapcha: tokenCapcha,
    };
    const headers = {
      application: 5,
    };
    try {
      const {
        data: { result },
      } = await axios.post(url, postJson, { headers: headers });
      await new Promise((resolve, reject) => {
        localStorage.setItem("login-response", JSON.stringify(result));
        resolve("");
      });

      setCookie("landlordUser", true, new Date(result?.exp).getDate());
      localStorage.setItem("deviceId", uuidv4());
      history.push("/dashboard");
    } catch (error) {
      console.log(error);
      setLoading(false);
      const { response } = error;
      refReCaptcha.current.execute()
      if (response?.data?.message) {
        setErrorMessage(response?.data?.message);
        if (response?.data?.message.search("password") > 0) {
          setLoginStatus(true);
        } else {
          setLoginStatus1(true);
        }
      } else {
        setLoginStatus(false);
        setLoginStatus1(false);
        message.error("Invalid Attempt");
      }
    }
  };

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Link to="/login" className="btn-tab-style active">
            Sign in
          </Link>
          <Link to="/signup" className="btn-tab-style">
            Sign up
          </Link>
        </Col>
        <Col span={24}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit(onFinish)}
          >
            <Popover
              placement="right"
              content={
                <Text type="danger">
                  <InfoCircleOutlined /> {errorMessage}
                </Text>
              }
              onVisibleChange={() => setLoginStatus1(false)}
              trigger="click"
              visible={loginStatus1}
            >
              <InputField
                fieldname="username"
                control={control}
                initValue=""
                iProps={{
                  placeholder: "Username / Email",
                  size: "large",
                  prefix: <UserOutlined className="site-form-item-icon" />,
                }}
                rules={{
                  required: "Please input username",
                  pattern: {
                    value: /(?=^.{1,50}$)^\S*$/,
                    message: "Input username without space!",
                  },
                }}
                validate={errors.username && "error"}
                validMessage={errors.username && errors.username.message}
              />
            </Popover>
            <Popover
              placement="right"
              content={
                <Text type="danger">
                  <InfoCircleOutlined /> {errorMessage}
                </Text>
              }
              onVisibleChange={() => setLoginStatus(false)}
              trigger="click"
              visible={loginStatus}
            >
              <InputPassword
                fieldname="password"
                control={control}
                initValue=""
                iProps={{
                  placeholder: "Password",
                  size: "large",
                  prefix: <LockOutlined className="site-form-item-icon" />,
                }}
                rules={{
                  pattern: {
                    value: /(?=^.{1,50}$)^\S*$/,
                    message: "Please enter correct password",
                  },
                  required: "Please enter password",
                }}
                validate={errors.password && "error"}
                validMessage={errors.password && errors.password.message}
              />
            </Popover>
            <Form.Item>
              {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

              <Link to="/forgot-password" className="login-form-forgot">
                Forgot password
              </Link>
            </Form.Item>

            <Form.Item className="mb-0">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
              >
                Sign in
              </Button>
            </Form.Item>
            <ReCaptcha
              action="main"
              sitekey={SITEKEY}
              ref={refReCaptcha}
              verifyCallback={verifyCallback}
            />
          </Form>
        </Col>

        {/* <div className="social-login">
            <span>Or, login with:</span>
            <Button type="link">
              <img src={thumbIcon} alt="Thumb" />
            </Button>
            <Button type="link">
              <img src={facebookIcon} alt="Facebook" />
            </Button>
            <Button type="link">
              <img src={googleIcon} alt="Google" />
            </Button>
          </div> */}
        <Col span={24}>
          <Paragraph className="copyright">Copyright © 2021</Paragraph>
        </Col>
      </Row>
    </LoginTemplate>
  );
};
