import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import DashboardLayout from '../../../molecules/DashboardLayout'
import { Col, Row, Typography } from 'antd'
import BreadCrumb from '../../../atoms/BreadCrumb'
import SectionHeader from '../../../molecules/SectionHeader'
import TicketsModule from '../../../molecules/Tickets'
import { getPresignedImage, getClosedTicketStatus, getInPrgTicketStatus, getNewTicketStatus, getAllCategories, getAllTechnicains, getNewTickets, getInprogressTickets, getCompleteTickets, getNewCount, getInprogressCount, getCompleteCount } from '../ducks/actions'
import ViewTicket from './components/ViewTicket';
import { Popup } from '../../../atoms/Popup';
import RejectTicket from './components/ViewTicket/rejectTicket';
const showOption = {
  value: "All",
  label: "All Technicians",
}
const categories = {
  value: "All",
  label: "All Categories",
};
const defaultNewStatusType = {
  value: "All",
  label: "All New Tickets",
}
const defaultInProgressStatusType = {
  value: "All",
  label: "All In Progress Tickets",
}
const defaultCompletedStatusType = {
  value: "All",
  label: "All Closed Tickets",
}
const Tickets = props => {

  const [technicians, setTechnicians] = useState([showOption]);
  const [ticketsType, setTicketType] = useState([categories]);
  const [technicianId, setTechnicianId] = useState();
  const [countNew, setCountNew] = useState(3);
  const [countProgress, setCountProgress] = useState(3);
  const [countClosed, setCountClosed] = useState(3)
  const [newStatusTypeId, setNewStatusTypeId] = useState();
  const [closedStatusTypeId, setClosedStatusTypeId] = useState();
  const [progressStatusTypeId, setProgressStatusTypeId] = useState();
  const [categoryName, setCategoryName] = useState();
  const [newStatusType, setNewStatusType] = useState([defaultNewStatusType]);
  const [completedStatusType, setCompletedtatusType] = useState([defaultCompletedStatusType]);
  const [progressStatusType, setProgressStatusType] = useState([defaultInProgressStatusType]);
  const [trigger, setTrigger] = useState(0);
  const [visible, setVisible] = useState(false);
  const [selectedTicket, setSelectedTickets] = useState();
  const [recurringTicket, setRecurringTicket] = useState();
  const [rejectTicketData, setRejectTicketData] = useState();
  const [tenantDetailData, setTenantDetailData] = useState();


  const location = useLocation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { Title } = Typography
  const breadcrumbList: any = [
    {
      label: "Organizations",
      route: "/organizations",
    },
    {
      label: "Tickets",
      route: "/tickets",
    },
  ];
  const newOrganizationTickets = useSelector((state) => state.organization.orgNewTickets)
  const inprogressOrganizationTickets = useSelector((state) => state.organization.orgInprogressTickets)
  const completeOrganizationTickets = useSelector((state) => state.organization.orgCompleteTickets)
  const newOrgTicketsCount = useSelector((state) => state.organization.orgNewTicketsCount)
  const inPrgOrgTicketsCount = useSelector((state) => state.organization.orgInprogressTicketsCount)
  const completeTicketsCount = useSelector((state) => state.organization.orgCompleteTicketsCount)
  const techniciansList = useSelector((state) => state.organization.getTechnicians)
  const categoriesList = useSelector((state) => state.organization.getCategories)
  const getNewStatus = useSelector((state) => state.organization.getNewStatus)
  const getInPrgStatus = useSelector((state) => state.organization.getInPrgStatus)
  const getClosedStatus = useSelector((state) => state.organization.getClosedStatus)


  const onTicketAPICall = () => {
    let params = {
      caseCategoryName: categoryName
    }
    dispatch(getNewTickets(id, countNew, { ...params, ...{ 'subStatus': newStatusTypeId } }))
    dispatch(getInprogressTickets(id, countProgress, { ...params, ...{ 'subStatus': progressStatusTypeId } }))
    dispatch(getCompleteTickets(id, countProgress, { ...params, ...{ 'subStatus': closedStatusTypeId } }))
    dispatch(getNewCount(id, { ...params, ...{ 'subStatus': newStatusTypeId } }));
    dispatch(getInprogressCount(id, { ...params, ...{ 'subStatus': progressStatusTypeId } }))
    dispatch(getCompleteCount(id, { ...params, ...{ 'subStatus': progressStatusTypeId } }))
    dispatch(getAllTechnicains())
    dispatch(getAllCategories())
    dispatch(getNewTicketStatus())
    dispatch(getInPrgTicketStatus())
    dispatch(getClosedTicketStatus())
  }
  function assignTechnician() {
    setTrigger(2);
  }

  function originalTicket(ticket) {
    viewDetail(ticket, true);
  }
  function activityLogTicket(ticket) {
    viewDetail(ticket, 'activity');
  }

  function rejectTicketTicket(ticket) {
    viewDetail(ticket, 'rejectTicket');
  }

  function viewTeanantDetail(ticket) {
    viewDetail(ticket, 'tenantDetail');
  }
  const onSubmit = () => {
    onTicketAPICall();
    setVisible(false);
  };


  useEffect(() => {
    onTicketAPICall()
  }, [categoryName])


  useEffect(() => {
    let categoryList = categoriesList && categoriesList.map((value, key) => {
      return ({
        label: value.display_name,
        value: value.name
      })
    })
    categoryList.unshift(categories)
    setTicketType(categoryList)
  }, [categoriesList])

  const statusAPI = () => {
    if (getNewStatus) {
      let data = getNewStatus.map((value, kye) => (
        {
          label: value.display_name,
          value: value.name
        }
      ))
      data.unshift(defaultNewStatusType)
      setNewStatusType(data)
    }
    if (getInPrgStatus) {
      let data = getInPrgStatus.map((value, key) => (
        {
          label: value.display_name,
          value: value.name
        }
      ))
      data.unshift(defaultInProgressStatusType)
      setProgressStatusType(data)
    }
    if (getClosedStatus) {
      let data = getClosedStatus.map((value, key) => (
        {
          label: value.display_name,
          value: value.name
        }
      ))
      data.unshift(defaultCompletedStatusType)
      setCompletedtatusType(data)
    }

  }

  useEffect(() => {
    statusAPI()
  }, [getNewStatus, getInPrgStatus, getClosedStatus])


  const handleShowTechnician = (value) => {
    setTechnicianId(value?.value == 'All' ? null : value?.value)
  }
  const handleShowCategory = (value) => {
    setCategoryName(value?.value == 'All' ? null : value?.value)
  }


  const countUpdate = (param) => {
    if (param === "new") {
      let param = {
        caseCategoryName: categoryName,
        subStatus: newStatusTypeId
      }
      setCountNew(countNew + 3);
      dispatch(getNewTickets(id, countNew + 3, param));

    } else if (param === "progress") {
      let param = {
        caseCategoryName: categoryName,
        subStatus: progressStatusTypeId
      }
      setCountProgress(countProgress + 3);
      dispatch(getInprogressTickets(id, countProgress + 3, param));
    }
    else if (param === "closed") {
      let param = {
        technicianId: technicianId,
        caseCategoryName: categoryName,
        subStatus: closedStatusTypeId
      }
      setCountClosed(countClosed + 3);
      dispatch(getCompleteTickets(id, countClosed + 3, param));
    }
  }

  const handleNewStatusChange = (value) => {
    let param = {
      caseCategoryName: categoryName,
      subStatus: value !== 'All' ? value : null
    }
    setNewStatusTypeId(value !== 'All' ? value : null);
    dispatch(getNewTickets(id, countNew, param));
    dispatch(getNewCount(id, param));
  }

  const handleInprgStatusChange = (value) => {
    let param = {
      caseCategoryName: categoryName,
      subStatus: value !== 'All' ? value : null
    }
    setProgressStatusTypeId(value !== 'All' ? value : null);
    dispatch(getInprogressTickets(id, countNew, param));
    dispatch(getInprogressCount(id, param));
  }

  const handleCloseStatusChange = (value) => {
    let param = {
      caseCategoryName: categoryName,
      subStatus: value !== 'All' ? value : null
    }
    setClosedStatusTypeId(value !== 'All' ? value : null);
    dispatch(getCompleteTickets(id, countNew, param));
    dispatch(getCompleteCount(id, param));
  }

  const popup = [

    {
      title: null,
      content: (
        <ViewTicket
          data={selectedTicket}
          onRecurring={(e) => originalTicket(e)}
          onActivityLog={(e) => activityLogTicket(e)}
          onRejectTicket={(e) => rejectTicketTicket(e)}
          onviewTenantDetails={(e) => viewTeanantDetail(e)}
          onCancel={() => setVisible(false)}
          assignTech={assignTechnician}
          onSubmit={onSubmit}
        />
      ),
      width: 920,
    },
    //   {
    //     title: (
    //       <Title level={3} className="ag-primary mb-0">
    //         Assign Technician
    //       </Title>
    //     ),
    //     content: (
    //       <AssignTechnician
    //         ticket={selectedTicket}
    //         onBack={() => setTrigger(1)}
    //         onAssign={onAssign}
    //       />
    //     ),
    //     width: 650,
    //   },
    //   {
    //     title: null,
    //     content: (
    //       <ViewTicket
    //         data={recurringTicket}
    //         onBack={() => {
    //           setTrigger(1);
    //           setRecurringTicket(null);
    //         }}
    //         onCancel={() => setVisible(false)}
    //         assignTech={assignTechnician}
    //       />
    //     ),
    //     width: 920,
    //   },
    //   {
    //     title: null,
    //     content: (
    //       <ActivityLog
    //       data={activityLogTicketData}
    //       onBack={() => {
    //         setTrigger(1);
    //         setActivityLogTicketData(null);
    //       }}
    //       onCancel={() => setVisible(false)}
    //       />
    //     ),
    //     width: 920,
    //   },
    {
      title: null,
      content: (
        <RejectTicket
          data={rejectTicketData}
          onBack={() => {
            setTrigger(0);
            setRejectTicketData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
    //   {
    //     title: null,
    //     content: (
    //       <ViewTenantDetail
    //       data={tenantDetailData}
    //       onBack={() => {
    //         setTrigger(1);
    //         setTenantDetailData(null);
    //       }}
    //       onCancel={() => setVisible(false)}
    //       />
    //     ),
    //     width: 920,
    //   },
  ];
  const popupProps = {
    closable: false,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };
  const getMedia = async (totalImages) => {
    const result = await getPresignedImage(totalImages);
    return result;
  };

  const viewDetail = async (item, recuring, type = '') => {
    const totalImages = [];
    const totalVideos = [];
    if (item?.CaseObject?.length) {
      item?.CaseObject?.map((e) => {
        if (e?.Object?.ImageObject) {
          totalImages.push(e?.Object?.ImageObject?.property_image);
        }
        if (e?.Object?.VideoObject) {
          totalVideos.push(e?.Object?.VideoObject?.property_video);
        }
      });
      const imagesA = await getMedia(totalImages);
      const videoA = await getMedia(totalVideos);
      item.totalImageURL = imagesA;
      item.totalVideoUrls = videoA;
    }
    if (recuring && recuring !== 'rejectTicket' && recuring !== 'tenantDetail') {
      setRecurringTicket({ ...item, ...{ 'ticketStatus': type } });
      setTrigger(3);
    } else if (recuring == 'rejectTicket') {
      setRejectTicketData({ ...item, ...{ 'ticketStatus': type } })
      setTrigger(1);
    } else if (recuring == 'tenantDetail') {

      setTenantDetailData({ ...item, ...{ 'ticketStatus': type } })
      setTrigger(6);
    }
    else {
      setSelectedTickets({ ...item, ...{ 'ticketStatus': type } });
      setVisible(true);
      setTrigger(0);
    }

  };
  async function viewTicket(item, type = '') {
    viewDetail(item, false, type)
  }

  return (
    <Fragment>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader heading={`${location.state.orgName} Tickets`} total={newOrgTicketsCount + inPrgOrgTicketsCount + completeTicketsCount}
              // showOption={technicians}
              // handleShow={handleShowTechnician}
              handleView={handleShowCategory}
              // exportAction={(e) => { }}
              viewOption={ticketsType}
              viewOptionLabel="Category"
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <TicketsModule
              ticketlist={newOrganizationTickets}
              category="New"
              count={newOrgTicketsCount}
              onCount={() => countUpdate("new")}
              viewTicket={(e) => viewTicket(e, 'new')}
              showStatusOption={newStatusType}
              handleStatusChange={handleNewStatusChange}
            // notifyCaseId={notifyCaseId}
            />
          </Col>
          <Col span={8}>
            <TicketsModule
              ticketlist={inprogressOrganizationTickets}
              category="In Progress"
              count={inPrgOrgTicketsCount}
              onCount={() => countUpdate("progress")}
              viewTicket={(e) => viewTicket(e, 'progress')}
              showStatusOption={progressStatusType}
              handleStatusChange={handleInprgStatusChange}
            // notifyCaseId={notifyCaseId}
            />
          </Col>
          <Col span={8}>
            <TicketsModule
              ticketlist={completeOrganizationTickets}
              category="Closed"
              count={completeTicketsCount}
              onCount={() => countUpdate("closed")}
              viewTicket={(e) => viewTicket(e, 'closed')}
              showStatusOption={completedStatusType}
              handleStatusChange={handleCloseStatusChange}
            // notifyCaseId={notifyCaseId}
            />
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </Fragment>
  );
};

export default Tickets;
